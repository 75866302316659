<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="close">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-6">
                        <img src="/assets/request/request-sent.svg" height="240" alt="Request was sent" class="margin-top-30">
                        <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center text-success">Thanks for your request</h3>
                        <p class="text-center">
                            Dear {{ displayName }}, we are working on this request for you. One of our talented agents will be reaching
                            out shortly and will be providing you with all the information required to get your request on its way.
                            In the meantime, we recommend you sit back and relax. We've got you covered.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ThankYouModal",
        props: {
            displayName: {
                type: String,
                required: true
            }
        },

        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>

<style scoped>

</style>