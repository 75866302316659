<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="closeModal">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <bucket-list :bucket-list-id="bucketListId" @open-component-page="closeModal"></bucket-list>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from "../misc/Loader"

    export default {
        components: {
          BucketList: () => import('../misc/BucketList'),
          Loader },

        props: {
            bucketListId: {
                required: true,
                validator(value) {
                    return (
                        _.isString(value) ||
                        _.isNumber(value)
                    );
                },
            },
        },

        methods: {
            closeModal() {
                this.$emit("close");
            },
        },
    }
</script>
