'use strict';

import Vue from 'vue';

Vue.filter('stripHtml', function (text) {
    try {
        let regex = /(<([^>]+)>)/ig;
        text = text.replace(regex, "");
    } catch (ignore) {}

    return text;
});