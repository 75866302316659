<template>
    <div class="jumbotron jumbotron-big text-center" :style="'background: url('+pictureUrl+') no-repeat;'">
        <!--<div class="dark-layer"></div>-->
        <div class="white position-absolute top-0 row h-100 w-100 justify-content-center align-items-center text-center margin-right-left-0">
            <dots-loader class="jumbo-info" v-if="loading" :dots-amount="4" :loader-style="'font-size: 40px'"></dots-loader>
            <div v-if="!loading" class="jumbo-info">
                <h1 class="text-uppercase font-xl white text-shadow">{{ title }} </h1>
                <h2 v-if="subtitle" class="text-uppercase font-lg white text-shadow">{{ subtitle }}</h2>
                <div v-if="addToMyBucketComponent">
                    <add-to-my-bucket-button v-if="!$globalVariables.get('settings.whiteLabel.is_white_label')" :component="addToMyBucketComponent" class="margin-top-40"></add-to-my-bucket-button>
                    <add-to-my-bucket-button-white-label v-if="$globalVariables.get('settings.whiteLabel.is_white_label')" :component="addToMyBucketComponent" class="margin-top-40"></add-to-my-bucket-button-white-label>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="controllers-cont">
            <next-section-arrow></next-section-arrow>
        </div>
    </div>
</template>

<script>
    import NextSectionArrow from "./../misc/NextSectionArrow";
    import AddToMyBucketButton from "./../misc/AddToMyBucketButton"
    import AddToMyBucketButtonWhiteLabel from "./../../white-label/components/misc/AddToMyBucketListButton"
    import DotsLoader from "./../misc/DotsLoader"

    export default {
        components: {NextSectionArrow, AddToMyBucketButton, AddToMyBucketButtonWhiteLabel, DotsLoader},
        props: {
            addToMyBucketComponent: {
                required: false,
                validator(value) {
                    if(!value)
                        return true;

                    // {component_type: string, component_id: ###}
                    return (
                        _.isObject(value) &&
                        _.has(value, "component_type") &&
                        _.has(value, "component_id")
                    );
                }
            },
            loading: {
                type: Boolean,
                default: false
            },
            pictureUrl: {
                type: String,
                required: true
            },
            subtitle: {
                type: String
            },
            title: {
                type: String,
                required: true
            },
        },
    }

</script>