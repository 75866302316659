<template>
    <a href="#" :class="cssClass" v-on:click.prevent="showPanelQuote">{{ text }}</a>
</template>

<script>
    import { vueSlideoutPanelService } from 'vue2-slideout-panel';
    import GetQuoteSidebar from "./../sidebars/GetQuoteSidebar"

    export default {
        props: {
            componentId: {
                default: null,
                type: Number|String
            },
            componentType: {
                default: "",
                type: String
            },
            cssClass: {
                type: String,
                default: "btn btn-primary btn-action btn-lg text-uppercase"
            },
            getComponentFromUri: {
                default: false,
                type: Boolean
            },
            text: {
                type: String,
                default: "Get a quote"
            },
        },

        methods: {
            showPanelQuote() {
                const props = {
                    componentId: this.componentId,
                    componentType: this.componentType
                };

                if(!this.componentId && !this.componentType && this.getComponentFromUri && this.$route)
                {
                    switch(this.$route.name)
                    {
                        case "hotel":
                            const component = this.$store.getters["Data/getHotelBySlug"](this.$route.params.slug);

                            if(component)
                            {
                                props.componentId = component.id;
                                props.componentType = "Hotel";
                            }

                            break;
                    }
                }

                vueSlideoutPanelService.show({
                    width: 550,
                    component: GetQuoteSidebar,
                    props: props,
                });
            }
        }
    }
</script>