import Vue from 'vue'
import Vuex from 'vuex'
import Auth from './Modules/Auth'
import Company from './Modules/Company'
import Data from './Modules/Data'
import QuoteCart from './Modules/QuoteCart'
import UserBucketList from './Modules/UserBucketList'
import Quotes from './Modules/Quotes'
import Users from './Modules/Users.js'
import UserSettings from './Modules/UserSettings'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Auth,
    Company,
    Data,
    QuoteCart,
    Quotes,
    UserBucketList,
    Users,
    UserSettings,
  },
  strict: false,
})
