'use strict';

export class HttpResponse
{
    constructor()
    {
        this.data = null;
        this.messages = [];
        this.meta = null;
        this.rawResponse = null;
        this.responseCode = 0;
        this.status = HttpResponseStatus.OK;
    }
}

export class HttpResponseStatus
{
    static get OK() {return "OK"};
    static get ERROR() {return "ERROR"};
}