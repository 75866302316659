<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="close">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <my-bucket-lists :component="finalComponent" @component-added-to-list="close"></my-bucket-lists>
            </div>
        </div>
    </div>
</template>

<script>
    import MyBucketLists from "./../misc/MyBucketLists"

    export default {
        components: { MyBucketLists },

        props: {
            component: {
                required: true,
                validator(value) {
                    // {component_type: string, component_id: ###}
                    return (
                        _.isObject(value) &&
                        _.has(value, "component_type") &&
                        _.has(value, "component_id")
                    );
                }
            },
        },

        computed: {
            finalComponent() {
                let component = _.clone(this.component);
                component.comments = "";
                return component;
            }
        },

        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>