<template>
    <section v-if="loading || featuredHotels.length >= 3" class="container-fluid section-style-top text-center padding-bottom-0">
        <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md">Featured hotels</h2>

        <div v-if="loading" class="row">
            <a class="col-md card-inside-cont placeholder" v-for="index in totalHotels">
                <div class="card-info row align-items-end background-enter">
                    <span class="col-xs-auto padding-right-left-0">
                        <loader></loader>
                    </span>
                </div>
            </a>
        </div>

        <div v-if="!loading" class="row">
            <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="col-md card-inside-cont zoom-image" v-for="hotel in featuredHotels" :key="hotel.id">
                <span class="zoom-image-hover"></span>
                <img :src="hotel.hotelPictures[0].path" alt="">
                <div class="card-info row align-items-end">
                    <span class="col-xs-auto padding-right-left-0">
                        <i class="material-icons white">business</i>
                    </span>
                    <div class="col row">
                        <span class="white col-12 text-left zoom-image-hover-text-1"><strong>{{ hotel.name }}</strong></span>
                        <span class="font-sm white col-12 text-left zoom-image-hover-text-2">{{ hotel.destination.name }}</span>
                    </div>
                </div>
            </router-link>
        </div>
    </section>
</template>

<script>
    import Loader from "./../misc/Loader"

    export default {
        components: { Loader },
        created() {
            this.initialize();
        },

        computed: {
            featuredHotels() {
                let response = [];

                if(this.hotels.length > 0)
                {
                    for(let i = 0; i < this.totalHotels; i++) {
                        if (this.hotels[i])
                        {
                            let hotel = this.hotels[i];

                            if(hotel.hotelPictures.length === 0)
                                hotel.hotelPictures.push({path: "/placeholder.jpg"});

                            response.push(hotel);
                        }
                    }
                }

                return response;
            }
        },

        data() {
            return {
                totalHotels: 3,
                hotels : [],
                loading: false
            }
        },

        methods: {
            initialize() {
                this.refreshHotels();
            },

             async refreshHotels() {
                this.loading = true;
                let response = await this.$httpClient.request("get", "/api/hotels/featured-hotels", {});
                this.hotels = response.data || [];
                this.loading = false
            }
        }
    }
</script>

<style lang="scss" type="text/scss">
    .placeholder {
        background-color: rgb(221, 221, 221);
        margin: 4px 2px 0px 2px;
        height: 320px;
    }
</style>