<template>
    <section>
        <div style="min-height: 100vh">
            <router-view></router-view>
        </div>

        <!-- Slide out Panel -->
        <vue-slideout-panel></vue-slideout-panel>

        <!-- Notifications -->
        <vue-snotify></vue-snotify>

        <!-- Modals -->
        <modals-container></modals-container>
        <v-dialog />
    </section>
</template>

<script>
    import { VueSlideoutPanel } from 'vue2-slideout-panel';
    import AOS from 'aos';
    import 'aos/dist/aos.css';

    export default {
        props: {
            globalSettings: {
                type: Object,
                default() { return {}; }
            }
        },
        created() {
            AOS.init();
            this.$globalVariables.set("settings", this.globalSettings);
        },
        components: { VueSlideoutPanel },
    }

</script>