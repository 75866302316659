<template>
    <div class="row">
        <div class="col">
            <a class="link-style" ref="formToggle" data-toggle="collapse" :href="'#create-new-list'+uniqueId" role="button" aria-expanded="false" aria-controls="create-new-list">Create a new list <i class="material-icons">add</i></a>
            <div :id="'create-new-list'+uniqueId" class="collapse">
                <bucket-list-form @created="toggleVisibility" @cancelled="toggleVisibility"></bucket-list-form>
            </div>
        </div>
    </div>
</template>

<script>
    import BucketListForm from "../forms/BucketListForm"

    export default {
        components: { BucketListForm },

        props: {
            hideOnCreate: {
                type: Boolean,
                default: true
            },
        },

        computed: {
            uniqueId() {
                return this._uid;
            },
        },

        methods: {
            toggleVisibility() {
                this.$refs.formToggle.click();
            },
        },
    }
</script>