'use strict';

import Vue from "vue"

// Import all services
import GlobalVariables from "./GlobalVariables";
import HttpClient from "./Http/HttpClient";
import Clipboard from "v-clipboard";

// Register all services
Vue.use(GlobalVariables);
Vue.use(HttpClient);
Vue.use(Clipboard);