import { parsePhoneNumberFromString } from 'libphonenumber-js'

/**
 * State
 */
const state = {
    companyInfo: {
        tollFreePhone: parsePhoneNumberFromString("+18002314095"),
        email: "info@ultimatejetvacations.com",
        phones: [
            {
                country: "United States",
                phone: parsePhoneNumberFromString("+18002314095"),
                flag: "us",
            },
            {
                country: "Brazil",
                phone: parsePhoneNumberFromString("+551137115947"),
                flag: "brazil",
            },
            {
                country: "World Wide",
                phone: parsePhoneNumberFromString("+13058300200"),
                flag: "world",
            },
            {
                country: "Mexico",
                phone: parsePhoneNumberFromString("+525553504776"),
                flag: "mexico",
            },
            {
                country: "United Kingdom",
                phone: parsePhoneNumberFromString("+442080992478"),
                flag: "england",
            },
            {
                country: "Canada",
                phone: parsePhoneNumberFromString("+14169003714"),
                flag: "canada",
            },
            {
                country: "Colombia",
                phone: parsePhoneNumberFromString("+5713819657"),
                flag: "colombia",
            }
        ]
    },
};

/**
 * Getters
 */
const getters = {
    companyInfo: state => {
        return state.companyInfo;
    },
};

export default {
    namespaced: true,
    state,
    getters,
}
