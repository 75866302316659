import Vue from "vue"
import init from "./../helpers/initializationValues"
import utils from "./../../services/utils"
import uuid from "uuid/v4"
import {HttpResponseStatus} from "../../services/Http/HttpResponse";

/**
 * State
 */
const state = {
    data: [],
    loading: false,
    initialized: init.NOT_INITIALIZED,
};

const defaultComponentStructure = {
    uuid: null,
    component: {
        id: null,
        type: null,
    },
    dateRange: null,
    adults: 2,
    children: 0,
    position: 0,
    componentData: null,
};

/**
 * Getters
 */
const getters = {
    loading: state => {
        return state.loading;
    },
    initialized: state => {
        return state.initialized;
    },
    data: state => {
        return _.cloneDeep(state.data);
    },
    defaultComponentStructure: () => {
        return _.cloneDeep(defaultComponentStructure);
    },
    getByIndex: state => index => {
        if(state.data[index] !== undefined)
            return _.cloneDeep(state.data[index]);

        return null;
    },
};

/**
 * Mutations
 */
const mutations = {
    DELETE_COMPONENT_FROM_DATA(state, index) {
        state.data.splice(index, 1);
        localStorage.quoteCart = JSON.stringify(state.data);
    },
    INSERT_COMPONENT_TO_DATA(state, component) {
        const comp = utils.assignFromDefault(_.cloneDeep(defaultComponentStructure), component);
        comp.uuid = uuid();

        state.data.unshift(comp);
        state.data = _.sortBy(state.data, ['position']);
        localStorage.quoteCart = JSON.stringify(state.data);
    },
    REPLACE_COMPONENT_TO_DATA(state, payload) {
        state.data[payload.index] = utils.assignFromDefault(_.cloneDeep(defaultComponentStructure), payload.component);
        state.data = _.sortBy(state.data, ['position']);
        localStorage.quoteCart = JSON.stringify(state.data);
    },
    UPDATE_DATA(state, value) {
        state.data = _.isArray(value) ? value : [];
    },
    UPDATE_INITIALIZED_STATE(state, value) {
        state.initialized = value;
    },
    UPDATE_LOADING_STATE(state, status) {
        state.loading = Boolean(status);
    },
};

/**
 * Actions
 */
const actions = {
    async addComponent(context, component)
    {
        if(context.getters.initialized === init.NOT_INITIALIZED)
            await context.dispatch("initializeData");

        context.commit("UPDATE_LOADING_STATE", true);
        context.commit("INSERT_COMPONENT_TO_DATA", component);
        context.commit("UPDATE_LOADING_STATE", false);

        return true;
    },
    async checkout(context, data)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let returnValue = false;
        const values = _.cloneDeep(context.getters.data);

        const components = _.map(values, (component) => {
            component.dateRange.end = Vue.prototype.$moment(component.dateRange.end).format("MM/DD/YYYY");
            component.dateRange.start = Vue.prototype.$moment(component.dateRange.start).format("MM/DD/YYYY");

            return component;
        });

        try {
            const response = await Vue.prototype.$httpClient.request("post", "/api/actions/quote-checkout", {components, data: data.data}, false);

            if(HttpResponseStatus.OK === response.status)
            {
                context.commit("UPDATE_DATA", []);
                returnValue = response.data;
                if (data.callback)
                    data.callback();
            }
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return returnValue;
    },
    async deleteComponent(context, id)
    {
        if(context.getters.initialized === init.NOT_INITIALIZED)
            return false;

        context.commit("UPDATE_LOADING_STATE", true);

        const index = _.findIndex(context.getters.data, {'uuid': id});

        if(index < 0)
            return  false;

        context.commit("DELETE_COMPONENT_FROM_DATA", index);

        context.commit("UPDATE_LOADING_STATE", false);

        return true;
    },
    async editComponent(context, component)
    {
        if(context.getters.initialized === init.NOT_INITIALIZED)
            return await context.dispatch("addComponent", component);

        context.commit("UPDATE_LOADING_STATE", true);

        const payload = {
            index: _.findIndex(context.getters.data, {'uuid': component.uuid}),
            component
        };

        context.commit("REPLACE_COMPONENT_TO_DATA", payload);

        context.commit("UPDATE_LOADING_STATE", false);

        return true;
    },
    async fetchData(context, cache)
    {
        if(cache && context.getters.data.length > 0)
            return;

        context.commit("UPDATE_LOADING_STATE", true);

        try {
            if(localStorage.quoteCart)
            {
                let data = _.map(JSON.parse(localStorage.quoteCart), (component) => {
                    if(component.dateRange)
                    {
                        component.dateRange.start = new Date(component.dateRange.start);
                        component.dateRange.end = new Date(component.dateRange.end);
                    }

                    return component;
                });

                context.commit("UPDATE_DATA", data);
            }
        } catch (e) {
            context.commit("UPDATE_DATA", []);
        }

        context.commit("UPDATE_LOADING_STATE", false);
    },
    async initializeData(context, cache)
    {
        if(
            context.getters.initialized === init.INITIALIZATION_IN_PROGRESS ||
            context.getters.initialized === init.INITIALIZED
        )
            return;

        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZATION_IN_PROGRESS);

        await context.dispatch("fetchData", cache);

        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZED);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

///////////////////////////////////////////
/////////////// HELPERS ///////////////////
///////////////////////////////////////////
function formatRequestHotels(requestHotels) {
    return _.reduce(requestHotels, (carry, requestHotel) =>
    {
        let totalPrice = 0;
        let minCheckin, maxCheckout = "";

        _.forEach(requestHotel.requestHotelRooms, (requestHotelRoom) => {
            const checkin = Vue.prototype.$moment(requestHotelRoom.checkin, "MM/DD/YYYY");
            const checkout = Vue.prototype.$moment(requestHotelRoom.checkout, "MM/DD/YYYY");
            totalPrice += Number(requestHotelRoom.total_price);

            if(
                checkin.isValid() &&
                (!minCheckin || checkin.isBefore(minCheckin))
            )
            {
                minCheckin = checkin;
            }

            if(
                checkout.isValid() &&
                (!maxCheckout || checkout.isAfter(maxCheckout))
            )
            {
                maxCheckout = checkout;
            }
        });

        requestHotel.total_price = totalPrice;
        requestHotel.min_checkin = minCheckin ? minCheckin.format("MM/DD/YYYY") : "";
        requestHotel.max_checkout = maxCheckout ? maxCheckout.format("MM/DD/YYYY") : "";
        requestHotel.nights = minCheckin && maxCheckout ? maxCheckout.diff(minCheckin, 'days') : 0;

        carry.push(requestHotel);

        return carry;
    }, []);
}