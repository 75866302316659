import Vue from "vue"
import {HttpResponseStatus} from "../../services/Http/HttpResponse"
import init from "./../helpers/initializationValues"
import { cloneDeep, forEach, groupBy, isArray, reduce } from "lodash";

/**
 * State
 */
const state = {
    festive: {
        data: [],
        loading: false,
        initialized: init.NOT_INITIALIZED
    },
    trending: {
        data: [],
        loading: false,
        initialized: init.NOT_INITIALIZED
    },
};

/**
 * Getters
 */
const getters = {
    loading: state => (type) => {
        return state[type].loading;
    },
    initialized: state => (type) => {
        return state[type].initialized;
    },
    data: state => (type) => {
        return cloneDeep(state[type].data);
    },
    byNightsStay: (state, getters) => (type) => {
        return groupBy(getters.data(type), 'nights');
    },
    byBudget: (state, getters) => (type) => {
        let response = [
            {title: "Quotes within $10K or less", data: []},
            {title: "Quotes $10K - $30K", data: []},
            {title: "Quotes $30K - $50K", data: []},
            {title: "Quotes $50K - $100K", data: []},
            {title: "Quotes $100K+", data: []},
        ];

        forEach(getters.data(type), (requestHotel) => {
            switch (true)
            {
                case (Number(requestHotel.total_price) < 10000):
                    response[0].data.push(requestHotel);
                    break;
                case (Number(requestHotel.total_price) < 30000):
                    response[1].data.push(requestHotel);
                    break;
                case (Number(requestHotel.total_price) < 50000):
                    response[2].data.push(requestHotel);
                    break;
                case (Number(requestHotel.total_price) < 100000):
                    response[3].data.push(requestHotel);
                    break;
                default:
                    response[4].data.push(requestHotel);
                    break;
            }
        });

        return response;
    },
    byDestination: (state, getters) => (type) => {
        return groupBy(getters.data(type), (requestHotel) => {
            return requestHotel.hotel.destination.name;
        });
    },
};

/**
 * Mutations
 */
const mutations = {
    UPDATE_DATA(state, payload) {
        state[payload.type].data = isArray(payload.data) ? payload.data : [];
    },
    UPDATE_INITIALIZED_STATE(state, payload) {
        state[payload.type].initialized = payload.initialized;
    },
    UPDATE_LOADING_STATE(state, payload) {
        state[payload.type].loading = Boolean(payload.loading);
    },
};

/**
 * Actions
 */
const actions = {
    async fetchData(context, type, cache)
    {
        if(cache && context.getters.settings)
            return;

        context.commit("UPDATE_LOADING_STATE", {type, loading: true});

        try {
            const response = await Vue.prototype.$httpClient.request("get", `/api/quotes/${type}`, {});
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if (data)
                context.commit("UPDATE_DATA", {type, data: formatRequestHotels(data)});
            else
                context.commit("UPDATE_DATA", {type, data: []});
        } catch (e) {
            context.commit("UPDATE_DATA", {type, data: []});
        }

        context.commit("UPDATE_LOADING_STATE", {type, loading: false});
    },
    async initializeData(context, type, cache)
    {
        if(
            context.getters.initialized(type) === init.INITIALIZATION_IN_PROGRESS ||
            context.getters.initialized(type) === init.INITIALIZED
        )
            return;

        context.commit("UPDATE_LOADING_STATE", {type, loading: true});
        context.commit("UPDATE_INITIALIZED_STATE", {type, initialized: init.INITIALIZATION_IN_PROGRESS});

        await context.dispatch("fetchData", type, cache);

        context.commit("UPDATE_INITIALIZED_STATE", {type, initialized: init.INITIALIZED});
        context.commit("UPDATE_LOADING_STATE", {type, loading: false});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

///////////////////////////////////////////
/////////////// HELPERS ///////////////////
///////////////////////////////////////////
function formatRequestHotels(requestHotels) {
    return reduce(requestHotels, (carry, requestHotel) =>
    {
        let totalPrice = 0;
        let minCheckin, maxCheckout = "";

        forEach(requestHotel.requestHotelRooms, (requestHotelRoom) => {
            const checkin = Vue.prototype.$moment(requestHotelRoom.checkin, "MM/DD/YYYY");
            const checkout = Vue.prototype.$moment(requestHotelRoom.checkout, "MM/DD/YYYY");
            totalPrice += Number(requestHotelRoom.total_price);

            if(
                checkin.isValid() &&
                (!minCheckin || checkin.isBefore(minCheckin))
            )
            {
                minCheckin = checkin;
            }

            if(
                checkout.isValid() &&
                (!maxCheckout || checkout.isAfter(maxCheckout))
            )
            {
                maxCheckout = checkout;
            }
        });

        requestHotel.total_price = totalPrice;
        requestHotel.min_checkin = minCheckin ? minCheckin.format("MM/DD/YYYY") : "";
        requestHotel.max_checkout = maxCheckout ? maxCheckout.format("MM/DD/YYYY") : "";
        requestHotel.nights = minCheckin && maxCheckout ? maxCheckout.diff(minCheckin, 'days') : 0;

        carry.push(requestHotel);

        return carry;
    }, []);
}