<template>
    <div style="min-height:550px">
        <loader :radius="10" :stroke="2" v-if="loading"></loader>

        <div v-if="!loading">
            <div v-if="bucketListComponents.length === 0" class="alert alert-warning box-shadow margin-top-40 box-round mr-auto ml-auto" role="alert">
                <div class="row">
                    <div class="col-2">
                        <i class="material-icons">shopping_basket</i>
                    </div>
                    <div class="col-10">
                        <h4 class="font-md"><strong>your bucket list is empty!</strong></h4>
                        <p class="margin-bottom-0">Add components to this list!</p>
                    </div>
                </div>
                <hr style="margin: 1.6rem 0">
                <p>
                    In order to add components to this list, you need to click on the button that appears in each destination or
                    hotel page to add it to your bucket. This is how the button looks like:<br>
                    <a href="#" @click.prevent="" role="button" aria-pressed="true" class="btn btn-primary btn-bucket btn-lg text-uppercase mt-3">
                        <i class="material-icons">favorite</i> Add to my bucket
                    </a>
                </p>
                <p>
                    Go to explore our collection of destinations and hotels and add them to your bucket!
                </p>
            </div>

            <div v-if="bucketListComponents.length > 0">
                <div class="row">
                    <div class="col-12">
                        <form @submit.prevent="submitForm">
                            <fieldset :disabled="loading">
                                <div class="form-group">
                                    <label class="font-sm">Your name</label>
                                    <input v-model="bucketListName" name="list-name" type="text" class="form-control input-style" placeholder="Enter your name..." />
                                    <input type="submit" value="Send bucket list" class="btn btn-primary btn-action btn-append"/>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
                <div>
                    <form-errors :errors="formErrors"></form-errors>
                </div>

                <vue-draggable tag="ul" v-model="bucketListComponents" handle=".drag-handle" class="margin-top-40 dragabble-list">
                    <li class="list-element" v-for="component in bucketListComponents" :key="component.id">
                        <div class="row align-items-center">
                            <i class="col-auto material-icons drag-handle">drag_handle</i>
                            <div class="d-none d-md-block col-md-3 align-self-center">
                                <img class="width-100 rounded" :src="component.main_thumbnail_picture_path" :alt="component.name"/>
                            </div>
                            <div class="col-8 col-md-6 mr-auto">
                                <h2 class="bd-title font-weight-bold font-md">{{ component.name }}</h2>
                                <p class="d-none d-md-block">{{ component.description || "" | stripHtml | truncate(100) }}</p>
                            </div>
                            <a href="#" @click.prevent="confirmDelete(component)" class="col-auto"><i class="material-icons">delete</i></a>
                        </div>

                        <div class="row">
                            <div class="col-6">
                                <a class="link-style" href="#" @click.prevent="openComponentPage('/'+component.component_type.toLowerCase()+'s/'+component.slug, component)">Visit {{ component.component_type.toLowerCase() }} page <i class="material-icons">keyboard_arrow_right</i></a>
                            </div>
                        </div>
                    </li>
                </vue-draggable>
            </div>
        </div>
    </div>
</template>

<script>
    import getLS from "./../../../services/localStorageBucketList";
    import Loader from "./../../../components/misc/Loader";
    import baseUrl from "../../mixins/baseUrl";
    import VueDraggable from "vuedraggable";
    import FormErrors from "./../../../components/misc/FormErrors"
    import Vue from "vue";
    import {HttpResponseStatus} from "../../../services/Http/HttpResponse";

    export default {
        components: { Loader, VueDraggable, FormErrors },

        mixins: [baseUrl],

        created() {
            this.initialize();
        },

        mounted() {
            this.updateComponents();
            getLS().addListener(this.lsKeyListener, this.updateComponents);
        },

        beforeDestroy() {
            getLS().removeListener(this.lsKeyListener);
        },

        computed: {
            bucketListComponents: {
                get() {
                    const vm = this;

                    if(vm.loading || vm.components.length === 0)
                        return [];

                    return _.reduce(vm.components, (response, component) =>
                    {
                        let comp = vm.$store.getters["Data/get"+component.component_type+"ById"](component.component_id);

                        if(comp)
                        {
                            comp = _.assign(comp, component);
                            response.push(comp);
                        }

                        return response
                    }, []);
                },
                set(value) {
                    getLS().put(_.map(value, item => {
                        return {
                            component_type: "Hotel",
                            component_id: item.id,
                        }
                    }));
                }
            },
            loading() {
                return this.$store.getters["Data/loading"] || this.isLoading;
            }
        },

        data() {
            return {
                bucketListName: "",
                components: [],
                formErrors: [],
                isLoading: false,
                lsKeyListener: _.uniqueId("white-label-bucket-list-"),
            };
        },

        methods: {
            initialize() {
                this.$store.dispatch("Data/initializeData");
            },

            confirmDelete(component) {
                const vm = this;

                vm.$modal.show('dialog', {
                    title: 'Are you sure you want to delete following component?',
                    text: 'Component: <strong>'+component.name+'</strong>',
                    class: "delete-confirmation-dialog",
                    buttons: [
                        {
                            title: 'Cancel',
                            class: 'btn btn-primary btn-lg text-uppercase'
                        },
                        {
                            title: 'Delete',
                            default: true,
                            class: 'btn btn-danger btn-lg text-uppercase',
                            handler: () => {
                                getLS().removeComponent(_.findIndex(getLS().load(), {component_type: "Hotel", component_id: component.id}));
                                vm.$modal.hide('dialog');
                            }
                        },
                    ]
                })
            },

            openComponentPage(path, component) {
                this.$router.push({path: this.baseUrl(path)});
                this.$emit("open-component-page", component);
            },

            async sendBucketList() {
                this.isLoading = true;

                const data = {
                    token: this.$route.params.token,
                    name: this.bucketListName,
                    components: this.components
                };

                try {
                    const response = await this.$httpClient.request("put", "/api/bucket-lists", data, true);

                    if(HttpResponseStatus.OK === response.status)
                    {
                        getLS().put([]);
                        this.$emit("sent", response);
                    }
                } catch (ignore) {
                    let html = `
                        <div class="snotifyToast__title"><strong>Error</strong></div>
                        <div class="snotifyToast__body" style="width: 100%">Whoops! Internal error. Please try again later</div>
                    `;

                    this.$snotify.error("", {html});
                }

                this.isLoading = false;
            },

            submitForm() {
                this.validateForm();

                if(this.formErrors.length === 0)
                    this.sendBucketList();
            },

            validateForm() {
                this.formErrors = [];

                if(!this.bucketListName)
                    this.formErrors.push("The name field is required.");

                if(!this.components.length)
                    this.formErrors.push("You must select at least one component in order to send a bucket list.");
            },

            updateComponents() {
                this.components = getLS().load()
            },
        },
    }
</script>