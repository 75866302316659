export default class AbstractDriver
{
    init()
    {
        throw new Error("Abstract not implemented");
    }

    lead()
    {
        throw new Error("Abstract not implemented");
    }

    pageView()
    {
        throw new Error("Abstract not implemented");
    }
}