<template>
    <div class="sidebar map-sidebar-full bg-light">
        <div class="sidebar-header side-header-transparent">
            <button class="close close-button" v-on:click.prevent="closePanel">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="sidebar-body no-padding">
            <div v-if="hotel" id="map-sidebar-full-hotel" class="">
                <div class="jumbotron jumbotron-extra-small text-center" :style="'background: url('+headerBackgroundUrl+') no-repeat;'">
                    <div class="dark-layer"></div>
                    <div class="white position-absolute top-0 row h-100 w-100 justify-content-center align-items-center text-center margin-right-left-0">
                        <div class="jumbo-info">
                            <h1 class="bd-title default-letter-spacing text-uppercase font-weight-bold font-md white">{{ hotel.name }}</h1>
                            <add-to-my-bucket-button v-if="!$globalVariables.get('settings.whiteLabel.is_white_label') && showGetQuote" :component="component" class="margin-top-40"></add-to-my-bucket-button>
                        </div>
                    </div>
                </div>
                <div class="bg-gray">
                    <nav class="nav nav-pills nav-fill bg-white">
                        <a class="nav-item nav-link" :class="{active: tab === 'overview'}" @click.prevent="tab = 'overview'" href="#"><i class="material-icons align-middle">subject</i></a>
                        <a class="nav-item nav-link" :class="{active: tab === 'rooms'}" @click.prevent="tab = 'rooms'" href="#"><i class="material-icons align-middle">hotel</i></a>
                    </nav>

                    <div class="tab-content">
                        <div :class="{active: tab === 'overview'}" class="tab-pane fade show" role="tabpanel">
                            <div class="map-sidebar-full-body-2 bg-light">

                                <!-- Overview -->
                                <div class="row">
                                    <div class="col">
                                        <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">
                                            Description
                                            <share-component-button v-if="showShareButton" class="float-right" :component="component"></share-component-button>
                                        </h2>
                                        <div>
                                            <p v-if="!readMoreExpanded" class="text-justify">
                                                {{ hotel.description || "" | truncate(250) }}
                                            </p>
                                            <p v-if="readMoreExpanded" class="text-justify">
                                                {{ hotel.description }}
                                            </p>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <a href="#" class="link-style" @click.prevent="readMoreExpanded = !readMoreExpanded">
                                                    <span v-if="!readMoreExpanded">Read more <i class="material-icons">add</i></span>
                                                    <span v-if="readMoreExpanded">Read less <i class="material-icons">remove</i></span>
                                                </a>
                                            </div>
                                            <div class="col text-right">
                                                <router-link @click.native="closePanel" :to="{name: 'hotel', params: {slug: hotel.slug}}" exact class="link-style">
                                                    Visit hotel page <i class="material-icons">keyboard_arrow_right</i>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Get a Quote btn -->
                                <div v-if="showGetQuote" class="row margin-top-40 margin-bottom-40 text-center">
                                    <div class="col">
                                        <get-quote-button :component-id="component.component_id" :component-type="component.component_type" @click.native="getQuoteOpened"></get-quote-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="{active: tab === 'special-offers'}" class="tab-pane fade show" role="tabpanel">
                            <div class="map-sidebar-full-body-2">
                                <div class="row">
                                    <div class="col">
                                         <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Special Offers</h2>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12" v-for="(promotion, key) in hotel.hotelValidPromotions" :key="promotion.id">
                                        <div class="box-element bg-white box-shadow d-flex flex-column rounded">
                                            <div class="box-element-info">
                                                <h3 class="font-md">
                                                    <a class="link-style font-weight-bold" href="#" @click.prevent="openModal('special-offer', {promotion, showGetQuote})">{{ promotion.name }}</a>
                                                </h3>
                                                <p class="margin-top-40">{{ promotion.description || "" | truncate(150) }}</p>
                                            </div>
                                            <div class="box-element-link text-center mt-auto">
                                                <a href="#" @click.prevent="openModal('special-offer', {promotion, showGetQuote})" class="font-weight-bold font-md link-style" >View offer</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="hotel.hotelValidPromotions.length === 0" class="col-12">
                                        <div class="box-element bg-white box-shadow d-flex flex-column rounded">
                                            <div class="box-element-info">
                                                <p class="font-md">
                                                    Please ask us for up to date promotions at this resort.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <!--div :class="{active: tab === 'rooms'}" class="tab-pane fade show" role="tabpanel">
                            <div class="map-sidebar-full-body-2">
                                <div class="row">
                                    <div class="col">
                                        <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Rooms</h2>
                                    </div>
                                </div>
                                <div class="box-element-lateral box-shadow rounded" v-for="hotelRoom in hotel.hotelRooms">
                                    <div class="row">
                                        <div class="col-12" v-if="hotelRoom.hotelRoomPictures && hotelRoom.hotelRoomPictures.length > 0" >
                                            <div class="box-element-image">
                                                <a href="#" @click.prevent="openModal('hotel-room', {hotelRoom, showGetQuote})" class="zoom-image">
                                                    <span class="zoom-image-hover"></span>
                                                    <img class="width-100 height-100" :src="hotelRoom.hotelRoomPictures[0].path" :alt="hotelRoom.name">
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="row margin-0 box-element bg-white rounded-bottom">
                                                <div class="col-12 box-element-info">
                                                    <h3 class="font-md">
                                                        <a href="#" @click.prevent="openModal('hotel-room', {hotelRoom, showGetQuote})" class="link-style font-weight-bold">{{ hotelRoom.category }} - {{ hotelRoom.name }}</a>
                                                    </h3>
                                                    <p class="margin-top-40 text-justify">{{ hotelRoom.description || "" | truncate(150) }}</p>
                                                </div>
                                                <div class="col-12 align-self-end box-element-link text-center">
                                                    <a href="#" @click.prevent="openModal('hotel-room', {hotelRoom, showGetQuote})" class="font-weight-bold font-md link-style">View room</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div-->
                    </div>
                </div>
            </div>
            <div v-if="!hotel">
                <loader class="margin-top-30" :radius="10" :stroke="2"></loader>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from "./../misc/Loader"
    import GetQuoteButton from "../misc/GetQuoteButton";
    import AddToMyBucketButton from "./../misc/AddToMyBucketButton"
    import ShareComponentButton from "./../misc/ShareComponentButton"
    import SpecialOfferModal from "./../modals/SpecialOfferModal"
    import HotelRoomModal from "./../modals/HotelRoomModal"

    export default {
        components: { AddToMyBucketButton, GetQuoteButton, Loader, ShareComponentButton },

        created() {
            this.initialize();
        },

        props: {
            slug: {
                required: true,
                type: String
            },
            showGetQuote: {
                default: true,
                type: Boolean
            },
            showShareButton: {
                default: true,
                type: Boolean
            }
        },

        computed: {
            amenities() {
                let response = [];

                if(this.hotel && this.hotel.virtuoso_amenities)
                    response = this.hotel.virtuoso_amenities.split(/\r?\n/).slice(0, 3);

                return response;
            },
            component() {
                return {
                    component_type: "Hotel",
                    component_id: this.hotel ? this.hotel.id : null
                }
            },
            defaultOfferBackgroundUrl() {
                if(!this.hotel)
                    return "";

                const firstPicture = _.find(this.hotel.hotelPictures, (picture) => { return picture.type === "Thumbnail"; });

                if(!firstPicture)
                    return "";

                return firstPicture.path;
            },
            headerBackgroundUrl() {
                if(!this.hotel)
                    return "";

                const firstPicture = _.find(this.hotel.hotelPictures, (picture) => { return picture.type === "Gallery"; });

                if(!firstPicture)
                    return "";

                return firstPicture.path;
            },
        },

        data() {
            return {
                tab: "overview",
                hotel: null,
                loading: false,
                readMoreExpanded: false,
            };
        },

        methods: {
            initialize() {
                this.fetchHotel();
            },

            closePanel() {
                this.$emit('closePanel', {});
            },

            async fetchHotel() {
                this.loading = true;

                await this.$store.dispatch("Data/initializeData");
                this.hotel = this.$store.getters["Data/getHotelBySlug"](this.slug);

                this.loading = false
            },

            getQuoteOpened() {
                /**
                 * SUPER UGLY WAY OF HIDING THIS PANEL
                 * WHEN THE QUOTE PANEL IS OPENED, BUT
                 * I COULD NOT FIND ANOTHER WAY
                 */
                this.$parent.$parent.panels.shift();
            },

            openModal(modal, props = {}) {
                let modalComponent = null;
                let options = {};

                switch (modal)
                {
                    case "hotel-room":
                        modalComponent = HotelRoomModal;
                        options.width = "700px";
                        break;
                    case "special-offer":
                        modalComponent = SpecialOfferModal;
                        options.width = "700px";
                        break;
                }

                this.$modal.show(modalComponent, props, _.assign({
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                }, options));
            },

            openPage(path) {
                this.$router.push({path});
                this.closePanel();
            },
        }
    }
</script>
