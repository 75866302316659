<template>
    <testimonies-carousel :testimonies="testimonies" :showAddButton="!!currentUser" @onClickAddTestimony="onClickAddTestimony"></testimonies-carousel>
</template>

<script>
    import TestimoniesCarousel from "../sections/TestimoniesCarousel";
    import TestimonyModal from "../modals/TestimonyModal";
    import {HttpResponseStatus} from "../../services/Http/HttpResponse";
    import { get, isArray } from "lodash";

    export default {
        components: { TestimoniesCarousel },
        created() {
            this.fetchTestimonies();
        },
        computed: {
            currentUser() {
                return this.$store.getters["Auth/user"];
            },
        },
        data() {
            return {
                isLoading: false,
                testimonies: []
            };
        },
        methods: {
            async fetchTestimonies() {
                this.isLoading = true;

                const response = await this.$httpClient.request('GET', `/api/testimonies/load`);

                this.isLoading = false;

                if (response.status === HttpResponseStatus.OK)
                {
                    const testimonies = get(response, 'data');

                    if (isArray(testimonies) && testimonies.length >= 5)
                    this.testimonies = testimonies;
                }

            },
            onClickAddTestimony(e) {
                this.$modal.show(TestimonyModal, {}, {
                    draggable: false,
                    height: 'auto',
                    scrollable: true,
                }, {});
            }
        },
    }

</script>

<style lang="scss">
    /** Unique Index Styles **/
</style>