<template>
    <gmap-map ref="gmap" :center="position" :zoom="zoom" :style="mapStyle">
        <gmap-marker :key="index" :ref="'marker'+index" v-for="(marker, index) in markers" :icon="marker.icon" :clickable="marker.clickable !== undefined ? marker.clickable : true" :position="marker.position" @click="markerClicked($event, index, marker)"></gmap-marker>
    </gmap-map>
</template>

<script>
    function validMarker(marker)
    {
        return (
            _.isObject(marker) &&
            _.has(marker, "position") &&
            _.has(marker.position, "lat") &&
            _.has(marker.position, "lng") &&
            _.isNumber(parseFloat(marker.position.lat)) &&
            _.isNumber(parseFloat(marker.position.lng))
        );
    }

    export default {
        props: {
            position: {
                required: true,
                validator(value) {
                    // {lat: ###, lng: ###}
                    return (
                        _.isObject(value) &&
                        _.has(value, "lat") &&
                        _.has(value, "lng") &&
                        _.isNumber(value.lat) &&
                        _.isNumber(value.lng)
                    );
                }
            },
            markers: {
                default() { return [] },
                validator(value) {
                    // [{position: {lat: ###, lng: ###} }]
                    if(!_.isArray(value)) return false;

                    if(value.length > 0)
                    {
                        const filtered = _.filter(value, (marker) => {
                            return validMarker(marker);
                        });

                        return value.length === filtered.length;
                    }

                    return true;
                }
            },
            mapStyle: {
                type: String,
                default: 'width:100%; height: 550px;'
            },
            zoom: {
                type: Number,
                required: true,
            }
        },
        methods: {
            markerClicked($event, index, customMarkerInfo) {
                const googleMapVueComponent = this.$refs.gmap;
                const googleMapMarkerVueComponent = this.$refs["marker"+index][0];

                this.$emit("marker-clicked", $event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo);
            }
        },
    };
</script>


<style lang="scss">
    /** Unique Index Styles **/
</style>
