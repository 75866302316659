import Vue from "vue"
import {HttpResponseStatus} from "../../services/Http/HttpResponse"
import init from "./../helpers/initializationValues"

/**
 * State
 */
const state = {
    settings: null,
    loading: false,
    initialized: init.NOT_INITIALIZED,
    modified: false
};

/**
 * Getters
 */
const getters = {
    loading: state => {
        return state.loading;
    },
    initialized: state => {
        return state.initialized;
    },
    settings: state => {
        return _.cloneDeep(state.settings);
    },
    modified: state => {
        return state.modified;
    },
};

/**
 * Mutations
 */
const mutations = {
    SET_WHITE_LABEL_SETTING_VALUE(state, payload) {
        state.settings.payload.white_label_website[payload.settingName] = payload.value;

    },
    UPDATE_INITIALIZED_STATE(state, value) {
        state.initialized = value;
    },
    UPDATE_LOADING_STATE(state, status) {
        state.loading = Boolean(status);
    },
    UPDATE_MODIFIED_STATE(state, status) {
        state.modified = Boolean(status);
    },
    UPDATE_USER_SETTINGS(state, settings) {
        const tmp = _.isObject(settings) ? settings : null;
        Vue.set(state, 'settings', tmp);
    },
};

/**
 * Actions
 */
const actions = {
    async fetchData(context, cache)
    {
        if(cache && context.getters.settings)
            return;

        context.commit("UPDATE_LOADING_STATE", true);

        try {
            const response = await Vue.prototype.$httpClient.request("get", "/api/user-settings/me", {});
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if (data)
                context.commit("UPDATE_USER_SETTINGS", data);
        } catch (e) {
            context.commit("UPDATE_USER_SETTINGS", null);
        }

        context.commit("UPDATE_MODIFIED_STATE", false);
        context.commit("UPDATE_LOADING_STATE", false);
    },
    async initializeData(context, cache)
    {
        if(
            context.getters.initialized === init.INITIALIZATION_IN_PROGRESS ||
            context.getters.initialized === init.INITIALIZED
        )
            return;

        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZATION_IN_PROGRESS);

        await context.dispatch("fetchData", cache);

        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZED);
    },
    async setWhiteLabelSettingValue(context, payload)
    {
        context.commit("SET_WHITE_LABEL_SETTING_VALUE", {settingName: payload.name, value: payload.value});
        context.commit("UPDATE_MODIFIED_STATE", true);
    },
    async saveSettings(context)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let returnValue = false;

        try {
            const response = await Vue.prototype.$httpClient.request("patch", "/api/user-settings/"+context.state.settings.id, context.state.settings, true);
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if(data)
            {
                context.commit("UPDATE_USER_SETTINGS", data);
                context.commit("UPDATE_MODIFIED_STATE", false);
                returnValue = true;
            }
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return returnValue;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}