import FacebookPixel from "./drivers/facebook-pixel";
import GoogleAnalytics from "./drivers/google-analytics";

/**
 * Driver structure:
 *  key: Should match the name
 *  name: should match the key
 *  driver: should be a class implementing AbstractDriver
 */
export const DRIVERS = {
    FacebookPixel: {
        name: 'FacebookPixel',
        driver: FacebookPixel,
    },
    GoogleAnalytics: {
        name: 'GoogleAnalytics',
        driver: GoogleAnalytics,
    },
};

export const DEFAULT_DRIVERS = process.env.NODE_ENV === 'development'
    ? []
    : [DRIVERS.FacebookPixel.name, DRIVERS.GoogleAnalytics.name];
