<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <vue-carousel ref="carousel" :data="data"></vue-carousel>
            </div>
        </div>
    </div>
</template>

<script>
    import VueCarousel from '@chenfengyuan/vue-carousel'

    export default {
        components: { VueCarousel },

        mounted() {
            this.$refs.carousel.slideTo(this.index);
        },

        props: {
            data: {
                required: true,
                type: Array
            },
            index: {
                type: Number,
                default: 0
            }
        }
    }
</script>