import AbstractDriver from "./drivers/abstract-driver";
import {DRIVERS, DEFAULT_DRIVERS} from './config';
import { includes, keys } from "lodash";

class MultiAnalytics
{
    /**
     *
     * @param {string[]} drivers
     */
    constructor(drivers = [])
    {
        this.drivers = drivers.map(driver => this._makeDriver(driver));
    }

    /**
     *
     * @returns {MultiAnalytics}
     */
    static getInstance()
    {
        return new this(DEFAULT_DRIVERS);
    }

    /**
     *
     * @param {string[]} drivers
     * @returns {MultiAnalytics}
     */
    static using(drivers = [])
    {
        return new this(drivers);
    }

    addDriver(driver)
    {
        this.drivers.push(this._makeDriver(driver));
    }

    init(config = {})
    {
        this.drivers.forEach(driver => {
            driver.init(config);
        })
    }

    lead()
    {
        this.drivers.forEach(driver => {
            driver.lead();
        })
    }

    pageView()
    {
        this.drivers.forEach(driver => {
            driver.pageView();
        })
    }

    /**
     *
     * @param {string} driver
     * @returns {AbstractDriver}
     * @private
     */
    _makeDriver(driver)
    {
        if (!includes(keys(DRIVERS), driver))
            throw new Error(`Driver: ${driver} not supported.`);

        const instance = new (DRIVERS[driver].driver);

        if (!instance instanceof AbstractDriver)
            throw new Error(`Driver: ${driver} is not an instance of AbstractDriver`);

        return instance;
    }
}

export {
    DRIVERS,
    MultiAnalytics
};

const multiAnalytics = MultiAnalytics.getInstance();

export default multiAnalytics;

