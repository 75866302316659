<template>
    <div class="next-section-anchor">
        <a @click="scrollDown"><i class="material-icons">arrow_downward</i></a>
    </div>
</template>

<script>
    export default {
        methods: {
            scrollDown() {
                const vm = this;
                window.$("html, body").animate({
                    scrollTop: $(vm.$el).offset().top,
                }, 1000)
            }
        },
    }
</script>