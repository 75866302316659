<template>
    <a href="#" role="button" aria-pressed="true" @click.prevent="openMyBucketList" class="btn btn-primary btn-bucket btn-lg text-uppercase">
        <i class="material-icons">favorite</i> Add to my bucket
    </a>
</template>

<script>
    import BucketListModal from "./../modals/BucketListModal"

    export default {
        props: {
            component: {
                required: true,
                validator(value) {
                    // {component_type: string, component_id: ###}
                    return (
                        _.isObject(value) &&
                        _.has(value, "component_type") &&
                        _.has(value, "component_id")
                    );
                }
            },
            radius: {
                type: Number,
                default: 5
            },
            stroke: {
                type: Number,
                default: 1
            },
        },
        methods: {
            openMyBucketList() {
                this.$modal.show(BucketListModal, {
                    component: this.component
                }, {
                    draggable: false,
                    height: 'auto',
                    width: "700px",
                    scrollable: true
                })
            },
        }
    }
</script>