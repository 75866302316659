<template>
    <div id="agent-home" v-if="currentUser">
        <section-banner-small v-if="currentUser" :title="currentUser.travelAgency.name" :subtitle="currentUser.full_name" :picture-url="'/assets/bg/white-label-home-bg.jpeg'"></section-banner-small>

        <!-- Top Hotels -->
        <section class="section-style text-center">
            <div class="container padding-right-left-0"
                 data-aos="fade-left"
                 data-aos-offset="100"
                 data-aos-once="true"
                 data-aos-duration="500">
                <div class="row">
                    <div class="col">
                        <h1 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md">Top hotels</h1>

                        <div class="row justify-content-center">
                            <div class="col-12 col-md-6 col-lg-4" v-for="hotel in featuredHotels">
                                <div class="box-element bg-white box-shadow d-flex flex-column">
                                    <div class="box-element-image">
                                        <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="zoom-image">
                                            <span class="zoom-image-hover"></span>
                                            <img class="width-100" :src="hotel.hotelPictures[0].path">
                                        </router-link>
                                    </div>
                                    <div class="box-element-info">
                                        <div class="row">
                                            <div class="col">
                                                <h3 class="font-md">
                                                    <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="link-style">
                                                        {{ hotel.name }}
                                                    </router-link>
                                                </h3>
                                                <p>{{ hotel.destination.name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-element-link text-center mt-auto">
                                        <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="link-style">View hotel</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <router-link :to="{name: 'hotels'}" class="btn btn-primary btn-lg text-uppercase margin-top-40 margin-bottom-40">View all hotels</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Why us -->
        <section class="section-style text-center" style="background-color: #0f0d1d !important;">
            <div class="container"
                 data-aos="fade-right"
                 data-aos-offset="100"
                 data-aos-once="true"
                 data-aos-duration="500">
                <h1 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md white">Why us?</h1>
                <div class="row section-explain justify-content-center">
                    <div class="col-12 col-sm-6 col-lg-4">
                        <i class="material-icons material-big white">domain</i>
                        <h3 class="font-md white">210+ Properties</h3>
                        <p class="white">We select only the best properties in top vacation destinations</p>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <i class="material-icons material-big white">room_service</i>
                        <h3 class="font-md white">24/7 Concierge Service</h3>
                        <p class="white">We have the contacts and means to create full travel experiences</p>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-4">
                        <i class="material-icons material-big white">place</i>
                        <h3 class="font-md white">45+ Fabulous Destinations</h3>
                        <p class="white">We select only the best properties in top vacation destinations</p>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact Us -->
        <section class="section-style text-center" v-if="currentUser.defaultEmail && currentUser.defaultPhone">
            <div class="container padding-right-left-0"
                 data-aos="fade-left"
                 data-aos-offset="100"
                 data-aos-once="true"
                 data-aos-duration="500">
                <div class="row">
                    <div class="col">
                        <h1 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md">Contact us</h1>
                        <div class="row section-explain justify-content-center">
                            <div class="col-12 col-sm-6 col-lg-4">
                                <i class="material-icons material-big">local_phone</i>
                                <h3 class="font-md">Call us now</h3>
                                <p><a :href="'tel:+'+currentUser.defaultPhone.phone_number">{{ currentUser.defaultPhone.display_phone }}</a></p>

                            </div>
                            <div class="col-12 col-sm-6 col-lg-4">
                                <i class="material-icons material-big">email</i>
                                <h3 class="font-md">Email us at</h3>
                                <p><a :href="'mailto:'+currentUser.defaultEmail.email">{{ currentUser.defaultEmail.email }}</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import { mapActions } from "vuex"
    import Page404 from "./404"
    import SectionBannerSmall from "./../../components/sections/BannerSmall"
    import SectionFeaturedHotels from './../../components/sections/FeaturedHotels';

    export default {
        components: { Page404, SectionBannerSmall, SectionFeaturedHotels },

        created() {
            this.initialize()
        },

        computed: {
            currentUser() {
                return this.$store.getters["Users/getByToken"](this.$route.params.token);
            },
            featuredHotels() {
                let response = [];

                if(this.hotels.length > 0)
                {
                    for(let i = 0; i < this.totalHotels; i++) {
                        if (this.hotels[i])
                        {
                            let hotel = this.hotels[i];

                            if(hotel.hotelPictures.length === 0)
                                hotel.hotelPictures.push({path: "/placeholder.jpg"});

                            response.push(hotel);
                        }
                    }
                }

                return response;
            },
        },

        data() {
            return {
                totalHotels: 3,
                hotels : [],
                loading: false
            }
        },

        methods: {
            ...mapActions({
                fetchUser: "Users/fetchUserByToken"
            }),

            initialize() {
                this.fetchUser({token: this.$route.params.token});
                this.refreshHotels();
            },

            async refreshHotels() {
                this.loading = true;
                let response = await this.$httpClient.request("get", "/api/hotels/featured-hotels", {});
                this.hotels = response.data || [];
                this.loading = false
            }
        }
    }
</script>
