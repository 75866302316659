<template>
    <!-- Testimonials -->
    <section class="bg-white section-style" style="margin-bottom: -50px;">
        <div class="container">
            <div class="row justify-content-center">
                <div v-if="!$globalVariables.get('settings.whiteLabel.is_white_label')" class="col-10 col-md-6">
                    <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">What travel agents are saying about UJV</h2>
                    <div id="agents-saying-slider" class="carousel slide" data-ride="carousel">
                        <div class="row justify-content-center">
                            <div class="col-12 col-lg-11">
                                <div class="carousel-inner text-center carousel-inner-no-controls">
                                    <div v-for="(testimony, index) in testimonies" class="carousel-item" :class="{'active': index === 0}">
                                        <p class="text-justify text-center">
                                            "{{ testimony.testimony }}"
                                        </p>
                                        <h3 class="font-md">{{ testimony.signature }}</h3>
                                    </div>
                                </div>
                                <a class="carousel-control-prev" href="#agents-saying-slider" role="button" data-slide="prev">
                                    <i class="material-icons">keyboard_arrow_left</i>
                                </a>
                                <a class="carousel-control-next" href="#agents-saying-slider" role="button" data-slide="next">
                                    <i class="material-icons">keyboard_arrow_right</i>
                                </a>
                                <ol class="carousel-indicators carousel-indicators-gray">
                                    <li data-target="#agents-saying-slider" v-for="(testimonial, index) in testimonies" :data-slide-to="index" :class="{'active': index === 0}"></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row margin-top-20" v-if="showAddButton">
                <div class="col-12 text-center">
                    <button class="btn btn-primary btn-lg text-uppercase" @click="$emit('onClickAddTestimony')">Add Your Testimonial</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        components: {  },
        props: {
            showAddButton: {required: true, type: Boolean, default: false},
            testimonies: {required: true, type: Array}
        },
        data() {
            return {

            };
        },

        methods: {

        },
    }

</script>

<style lang="scss">
    /** Unique Index Styles **/
</style>