import Vue from "vue"
import init from "./../helpers/initializationValues"
import {flatMap, filter, sample} from "lodash";

/**
 * State
 */
const state = {
    regions: [],
    destinations: [],
    hotels: [],
    loading: false,
    initialized: init.NOT_INITIALIZED,
};

/**
 * Getters
 */
const getters = {
    loading: state => {
        return state.loading;
    },
    initialized: state => {
        return state.initialized;
    },
    destinations: state => {
        return _.cloneDeep(state.destinations);
    },
    getDestinationById: (state, getters) => (id) => {
        let response = null;

        _.forEach(getters.destinations, (destination) => {
            if(destination.id === id)
            {
                response = _.clone(destination);
                return false;
            }
        });

        return response;
    },
    getDestinationBySlug: (state, getters) => (slug) => {
        let response = null;

        _.forEach(getters.destinations, (destination) => {
            if(destination.slug === slug)
            {
                response = _.clone(destination);
                return false;
            }
        });

        return response;
    },
    getDestinationsById: (state, getters) => (ids) => {
        let response = [];

        _.forEach(getters.destinations, (destination) => {
            const idIndex = ids.indexOf(destination.id);

            if(idIndex !== -1)
            {
                response.push(_.clone(destination));
                ids.splice(idIndex, 1); // Remove id from searching ids
            }

            return ids.length > 0;
        });

        return response;
    },
    getHotelById: (state, getters) => (id) => {
        let responseHotel = null;

        _.forEach(getters.hotels, (hotel) => {
            if(hotel.id === id)
            {
                responseHotel = _.clone(hotel);
                return false;
            }
        });

        return responseHotel;
    },
    getHotelBySlug: (state, getters) => (slug) => {
        let responseHotel = null;

        _.forEach(getters.hotels, (hotel) => {
            if(hotel.slug === slug)
            {
                responseHotel = _.clone(hotel);
                return false;
            }
        });

        return responseHotel;
    },
    getHotelsById: (state, getters) => (ids) => {
        let responseHotel = [];

        _.forEach(getters.hotels, (hotel) => {
            const idIndex = ids.indexOf(hotel.id);

            if(idIndex !== -1)
            {
                responseHotel.push(_.clone(hotel));
                ids.splice(idIndex, 1); // Remove id from searching ids
            }

            return ids.length > 0;
        });

        return responseHotel;
    },
    getHotelRoomBySlug: (state, getters) => (hotelSlug, roomSlug) => {
        let responseHotelRoom = null;

        _.forEach(getters.hotelRooms(hotelSlug), (room) => {
            if(room.slug === roomSlug)
            {
                responseHotelRoom = _.clone(room);
                return false;
            }
        });

        return responseHotelRoom;
    },
    getPromotions: (state, getters) => () => {
        return _.flatMap(getters.hotels, (hotel) => {
            return hotel.hotelValidPromotions.map(promotion => {
                return {
                    picture: _.get(_.filter(hotel.hotelPictures, {type: 'Thumbnail'}), '0.path'),
                    ...promotion
                }
            });
        });
    },
    hotels: state => {
        return _.cloneDeep(state.hotels);
    },
    hotelRooms: (state, getters) => (hotelSlug) => {
        let hotelRooms = [];
        const hotel = getters.getHotelBySlug(hotelSlug);

        if(hotel && hotel.hotelRooms.length)
            hotelRooms = _.map(hotel.hotelRooms, (room) => {
                room.hotel = _.clone(hotel);
                delete room.hotel["hotelRooms"];

                return room;
            });

        return _.cloneDeep(hotelRooms);
    },
    regions: state => {
        return _.cloneDeep(state.regions);
    },
};

/**
 * Mutations
 */
const mutations = {
    UPDATE_DESTINATIONS(state, destinations) {
        state.destinations = _.isArray(destinations) ? destinations : [];
    },
    UPDATE_HOTELS(state, hotels) {
        state.hotels = _.isArray(hotels) ? hotels : [];
    },
    UPDATE_REGIONS(state, regions) {
        state.regions = _.isArray(regions) ? regions : [];
    },
    UPDATE_INITIALIZED_STATE(state, value) {
        state.initialized = value;
    },
    UPDATE_LOADING_STATE(state, status) {
        state.loading = Boolean(status);
    }
};

/**
 * Actions
 */
const actions = {
    async fetchData(context, cache)
    {
        cache = typeof cache === "undefined" ? true : Boolean(cache);

        if(cache && context.getters.initialized === init.INITIALIZED)
            return;

        let records = [];

        try {
            let response = await Vue.prototype.$httpClient.request("get", "/api/regions", {});
            records = response.data || [];
        } catch (ignore) {
            records = [];
        }

        context.commit("UPDATE_REGIONS", records);
        context.commit("UPDATE_DESTINATIONS", formatDestinationsFromRegions(context.getters.regions));
        context.commit("UPDATE_HOTELS", formatHotelsFromDestinations(context.getters.destinations));
    },
    async initializeData(context, cache)
    {
        if(
            context.getters.initialized === init.INITIALIZATION_IN_PROGRESS ||
            context.getters.initialized === init.INITIALIZED
        )
            return;

        context.commit("UPDATE_LOADING_STATE", true);
        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZATION_IN_PROGRESS);

        await context.dispatch("fetchData", cache);

        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZED);
        context.commit("UPDATE_LOADING_STATE", false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

///////////////////////////////////////////
/////////////// HELPERS ///////////////////
///////////////////////////////////////////
function formatDestinationsFromRegions(regions) {
    let destinations = [];

    _.forEach(regions, (region) => {
        const formattedDestinations = _.reduce(region.destinations, (response, destination) => {

            destination.region = _.clone(region);
            destination.main_header_picture_path = "";
            destination.main_gallery_picture_path = "";
            destination.main_thumbnail_picture_path = "";
            delete destination.region["destinations"];

            for(let i in destination.destinationPictures)
            {
                if(destination.destinationPictures[i].type === "Header")
                    destination.main_header_picture_path = destination.destinationPictures[i].path;
                else if(destination.destinationPictures[i].type === "Gallery")
                    destination.main_gallery_picture_path = destination.destinationPictures[i].path;
                else if(destination.destinationPictures[i].type === "Thumbnail")
                    destination.main_thumbnail_picture_path = destination.destinationPictures[i].path;

                if(destination.main_header_picture_path && destination.main_gallery_picture_path && destination.main_thumbnail_picture_path)
                    break;
            }

            if (!destination.main_thumbnail_picture_path)
            {
                const childrenHotels = flatMap(_.filter(region.destinations, { parent_id: destination.id }), 'hotels');
                const picture = sample(filter(flatMap(childrenHotels.concat(destination.hotels), 'hotelPictures'), {type: 'Thumbnail'}));

                if (picture)
                {
                    destination.main_thumbnail_picture_path = picture.path;
                }
            }

            response.push(destination);

            return response
        }, []);

        destinations = destinations.concat(formattedDestinations);
    });

    if(destinations.length)
        destinations = _.orderBy(destinations, ["name"], ["asc"]);

    return destinations;
}

function formatHotelsFromDestinations(destinations) {
    let hotels = [];

    _.forEach(destinations, (destination) => {
        const formattedHotels = _.reduce(destination.hotels, (response, hotel) => {

            hotel.destination = _.clone(destination);
            hotel.main_header_picture_path = "";
            hotel.main_gallery_picture_path = "";
            hotel.main_thumbnail_picture_path = "";
            delete hotel.destination["hotels"];

            for(let i in hotel.hotelPictures)
            {
                if(hotel.hotelPictures[i].type === "Header")
                    hotel.main_header_picture_path = hotel.hotelPictures[i].path;
                else if(hotel.hotelPictures[i].type === "Gallery")
                    hotel.main_gallery_picture_path = hotel.hotelPictures[i].path;
                else if(hotel.hotelPictures[i].type === "Thumbnail")
                    hotel.main_thumbnail_picture_path = hotel.hotelPictures[i].path;

                if(hotel.main_header_picture_path && hotel.main_gallery_picture_path && hotel.main_thumbnail_picture_path)
                    break;
            }

            response.push(hotel);

            return response
        }, []);

        hotels = hotels.concat(formattedHotels);
    });

    if(hotels.length)
        hotels = _.orderBy(hotels, ["name"], ["asc"]);

    return hotels;
}
