<template>
    <div id="destination-page">
        <page-404 v-if="!destinationFound"></page-404>

        <div v-if="destinationFound">
            <section-banner-big :picture-url="mainPicture" :title="title" :loading="loading" :add-to-my-bucket-component="currentUser ? component : null"></section-banner-big>

            <div v-if="destination" class="fixed-spy">
                <div class="container">
                    <div class="row">
                        <!-- Side menu -->
                        <div class="d-none d-lg-block col-lg-3 lateral-menu-cont">
                            <div class="fixed-element">
                                <ul v-scroll-spy-active v-scroll-spy-link class="section-nav lateral-menu-jump">
                                    <li>
                                        <a class="font-md link-style d-block">
                                            <i class="material-icons">subject</i> <span class="d-none d-lg-inline">Overview</span>
                                        </a>
                                    </li>
                                    <li v-if="hotels.length">
                                        <a class="font-md link-style d-block">
                                            <i class="material-icons">business</i> <span class="d-none d-lg-inline">Hotel and villas</span>
                                        </a>
                                    </li>
                                    <add-to-my-bucket-button v-if="!$globalVariables.get('settings.whiteLabel.is_white_label') && currentUser" :component="component"></add-to-my-bucket-button>
                                    <a v-if="!$globalVariables.get('settings.whiteLabel.is_white_label')" :href="companyInfo.tollFreePhone.getURI()" class="font-md link-style text-center margin-top-10 d-none d-lg-block">Call us: <span class="d-inline-block">{{ companyInfo.tollFreePhone.formatInternational() }}</span></a>
                                </ul>
                            </div>
                        </div>

                        <!-- Main content -->
                        <div class="col-lg-9 lateral-main-cont">
                            <div v-scroll-spy="{offset: 100}">

                                <!-- Overview -->
                                <section class="section-style px-xs-0">
                                    <div data-aos="fade-left"
                                        data-aos-offset="100"
                                        data-aos-once="true"
                                        data-aos-duration="500">
                                        <div class="row margin-bottom-40">
                                            <div class="col text-right">
                                                <share-component-button v-if="currentUser" :component="component"></share-component-button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Description</h2>
                                                <p class="text-justify" v-if="!readModeDescriptionExpanded">{{ destination.description || "" | stripHtml | truncate(250) }}</p>
                                                <p class="text-justify" v-if="readModeDescriptionExpanded" v-html="destination.description"></p>
                                                <a href="#" @click.prevent="readModeDescriptionExpanded = !readModeDescriptionExpanded" class="link-style">
                                                    Read
                                                    <span v-if="!readModeDescriptionExpanded">more <i class="material-icons">add</i></span>
                                                    <span v-if="readModeDescriptionExpanded">less <i class="material-icons">remove</i></span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </section>

                                <!-- Hotel Section -->
                                <section v-if="hotels.length" class="section-style bg-white section-style-white-lateral px-xs-0">
                                    <div class="container"
                                        data-aos="fade-left"
                                        data-aos-offset="100"
                                        data-aos-once="true"
                                        data-aos-duration="500">
                                        <div class="row justify-content-end">
                                            <div class="col-lg-9 lateral-main-cont">
                                                <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Hotels and Villas</h2>
                                                <div class="box-element-lateral box-shadow rounded" v-for="(hotel, index) in hotels" :key="hotel.id">
                                                    <div class="row" :class="{'flex-sm-row-reverse': index % 2 !== 0}">
                                                        <div class="col-12 col-md-6" :class="{'box-element-lateral-right': index % 2 === 0, 'box-element-lateral-left': index % 2 !== 0}">
                                                            <div class="box-element-lateral-image">
                                                                <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="zoom-image">
                                                                    <span class="zoom-image-hover"></span>
                                                                    <img class="width-100 height-100" :src="hotel.mainPicturePath" :alt="hotel.name">
                                                                </router-link>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6" :class="{'box-element-lateral-left': index % 2 === 0, 'box-element-lateral-right': index % 2 !== 0}">
                                                            <div class="row margin-0 box-element bg-light">
                                                                <div class="col-12 box-element-info">
                                                                    <h3 class="font-md">
                                                                        <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="link-style font-weight-bold">{{ hotel.name }}</router-link>
                                                                    </h3>
                                                                    <p class="margin-top-40">{{ hotel.description || "" | stripHtml | truncate(200) }}</p>
                                                                </div>
                                                                <div class="col-12 align-self-end box-element-link text-center">
                                                                    <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="link-style font-weight-bold font-md">View hotel</router-link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SectionInteractiveMap from "./../components/sections/InteractiveMap"
    import SectionBannerBig from "./../components/sections/BannerBig"
    import ShareComponentButton from "./../components/misc/ShareComponentButton"
    import AddToMyBucketButton from "../components/misc/AddToMyBucketButton"
    import SpecialOfferModal from "./../components/modals/SpecialOfferModal"
    import Page404 from "./404"

    export default {
        components: {Page404, AddToMyBucketButton, SectionInteractiveMap, SectionBannerBig, ShareComponentButton },

        created() {
            this.initialize();
        },

        computed: {
            currentUser() {
                return this.$store.getters["Auth/user"]
            },
            component() {
                return {
                    component_type: "Destination",
                    component_id: this.destination ? this.destination.id : null
                };
            },
            hotels() {
                if(!this.destination) return [];

                const mainHotels = this.getDestinationHotels(this.destination);

                const childrenHotels = _.filter(this.$store.getters["Data/destinations"], { parent_id: this.destination.id }).reduce((hotels, destination) => hotels.concat(this.getDestinationHotels(destination)), []);

                return mainHotels.concat(childrenHotels);
            },
            mainPicture() {
                if(!this.destination) return "";

                let response = "https://assets.travelagentadmin.net/Images/Hotels/Pictures/amangiri_1508182240_0.jpeg";
                let mainPicture = null;

                if(this.destination.destinationPictures.length)
                    mainPicture = _.find(this.destination.destinationPictures, {"type": "Header"});

                if(!mainPicture && this.hotels.length)
                {
                    _.forEach(this.hotels, (hotel) => {
                        mainPicture = _.first(hotel.hotelPictures, {"type": "Header"});

                        if(mainPicture)
                            return false;
                    });
                }

                if(mainPicture)
                    response = mainPicture.path;

                return response
            },
            promotions() {
                let response = [];

                _.forEach(this.hotels, (hotel) => {
                    response = response.concat(_.map(hotel.hotelValidPromotions, (promotion) => {
                        promotion.name = hotel.name + ": " + promotion.name;
                        return promotion;
                    }));
                });

                return response;
            },
            title() {
                return this.destination ? this.destination.name : "";
            },
        },

        data: function () {
            return {
                loading: false,
                destination: null,
                destinationFound: true,
                readModeDescriptionExpanded: false,
                companyInfo: this.$store.getters["Company/companyInfo"]
            };
        },

        methods: {
            async fetchDestination() {
                this.loading = true;

                await this.$store.dispatch("Data/initializeData");
                this.destination = this.$store.getters["Data/getDestinationBySlug"](this.$route.params.slug);
                this.destinationFound = !!this.destination;

                this.loading = false;
            },

            getDestinationHotels(destination)
            {
                return _.map(destination.hotels, (hotel) => {
                    const response = hotel;
                    response.mainPicturePath = "";

                    const mainPicture = _.find(hotel.hotelPictures, (picture) => {
                        return picture.type === "Gallery";
                    });

                    if(mainPicture)
                        response.mainPicturePath = mainPicture.path;

                    return response;
                });
            },

            initialize() {
                this.fetchDestination();
            },

            openSpecialOfferModal(promotion) {
                this.$modal.show(SpecialOfferModal, {
                    promotion
                }, {
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                })
            }
        },

        watch: {
            '$route.params.slug': function () {
                this.initialize();
            }
        },
    }
</script>
