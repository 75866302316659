'use strict';

import Vue from 'vue';

Vue.filter('truncate', function(value, limit) {
    if (!value)
        return '';

    if (value.length > limit) {
        value = value.substring(0, (limit - 3)) + '...';
    }

    return value;
});