/**
 * data should be composed of objects of the following form:
 * {componentType: String, componentId: ####}
 */
const LOCAL_STORAGE_KEY = "LS_BUCKET_LIST";

class Manager
{
    constructor()
    {
        this.listeners = {};

        if(!localStorage.getItem(LOCAL_STORAGE_KEY))
            this.put([]);
    }

    _executeListeners()
    {
        const value = this.load();

        _.forEach(this.listeners, handler => handler(value));
    }

    addListener(key, handler)
    {
        this.listeners[key] = handler;
    }

    load()
    {
        return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    }

    put(value)
    {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));

        this._executeListeners();
    }

    pushComponent(value)
    {
        if(!_.isObject(value) || !_.has(value, "component_type") || !_.has(value, "component_id"))
            throw new Error("Trying to add invalid component to localStorage bucket list");

        const storage = this.load();
        storage.push(value);
        this.put(storage);

        this._executeListeners();
    }

    putComponent(index, value)
    {
        if(!_.isObject(value) || !_.has(value, "componentType") || !_.has(value, "componentId"))
            throw new Error("Trying to add invalid component to localStorage bucket list");

        const storage = this.load();
        storage.splice(index, 0, value);
        this.put(storage);

        this._executeListeners();
    }

    removeAll()
    {
        this.put([]);

        this._executeListeners();
    }

    removeComponent(index)
    {
        const storage = this.load();
        storage.splice(index, 1);
        this.put(storage);

        this._executeListeners();
    }

    removeListener(key)
    {
        if(_.has(this.listeners, key))
            delete this.listeners[key];
    }
}

const singletonInstance = new Manager();

export default function () {
    return singletonInstance;
};