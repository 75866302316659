<template>
    <div id="concierge-services-page">

        <!-- Content Section -->
        <section class="section-style" v-if="!loading">
            <div class="component-wrapper" v-for="component in getComponentsByType(bucketListComponents, 'Destination')" :key="component.id+'-'+component.updated_at">
                <bucket-destination :destination="component"></bucket-destination>
            </div>

            <div class="component-wrapper" v-for="component in getComponentsByType(bucketListComponents, 'Hotel')" :key="component.id+'-'+component.updated_at">
                <bucket-hotel :hotel="component"></bucket-hotel>
            </div>
        </section>

        <loader class="margin-top-120" v-if="loading" :radius="10" :stroke="2"></loader>
    </div>
</template>

<script>
    import BannerSmall from "../../components/sections/BannerSmall";
    import BucketDestination from "../components/BucketDestination";
    import BucketHotel from "../components/BucketHotel";
    import Loader from "../../components/misc/Loader"
    import _ from "lodash";

    export default {

        components: {
            BannerSmall,
            BucketDestination,
            BucketHotel,
            Loader
        },

        computed: {
            bucketList() {
                return this.$store.getters["UserBucketList/getByUuid"](this.$route.params.bucketListUuid);
            },
            bucketListComponents: {
                get() {
                    const vm = this;

                    if(vm.loading || !vm.bucketList || vm.bucketList.userBucketListComponents.length === 0)
                        return [];

                    return _.reduce(vm.bucketList.userBucketListComponents, (response, component) =>
                    {
                        let comp = vm.$store.getters["Data/get"+component.component_type+"ById"](component.component_id);

                        if(comp)
                        {
                            comp = _.assign(component, comp);
                            response.push(comp);
                        }

                        return response
                    }, []);
                },
                set(value) {
                    const vm = this;

                    vm.$store.dispatch("UserBucketList/updateBucketListComponentsOrder", {
                        userBucketListId: vm.bucketList.id,
                        userBucketListComponentIds: _.map(value, "id"),
                    });
                }
            },
            loading() {
                return this.$store.getters["Data/loading"];
            }
        },

        data: function () {
            return {
                name: 'BucketList',
                message: 'Bucket List Page',
            };
        },

        methods: {
            getComponentsByType(components, type) {
                return _.filter(components, {component_type: type});
            },
        },

        mounted() {
            this.$store.dispatch("Data/initializeData");

            this.$store.dispatch("UserBucketList/getBucketListByUuid", {
                bucketListUuid: this.$route.params.bucketListUuid
            });
        }
    }
</script>

<style lang="scss">
    #concierge-services-page {
        margin-top: 100px;
    }
</style>
