<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-auto position-relative">
                <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">
                    <span v-if="componentInfo">Select a list to add this {{ componentInfo.component_type }} or create a new list!</span>
                    <span v-if="!componentInfo">My bucket lists</span>
                </h3>
                <loader v-if="loading" :radius="10" :stroke="2"></loader>
            </div>
        </div>

        <div class="row align-self-start mb-5" v-if="componentInfo">
            <div class="d-none d-md-block col-md-3">
                <img class="width-100 margin-top-5 rounded" :src="componentInfo.main_thumbnail_picture_path" :alt="componentInfo.name"/>
            </div>
            <div class="col-12 col-md-9">
                <h3 class="bd-title font-weight-bold font-md">{{ componentInfo.name }}</h3>
                <p class="d-none d-md-block">{{ componentInfo.description || "" | stripHtml | truncate(100) }}</p>
                <div class="row">
                    <div class="col-12 text-right">
                        <a class="link-style" data-toggle="collapse" :href="'#component-message-'+componentInfo.component_type+'-'+componentInfo.id" role="button" aria-expanded="false" aria-controls="plane-details">Add comments <i class="material-icons">keyboard_arrow_down</i></a>
                    </div>
                </div>
            </div>
            <div :id="'component-message-'+componentInfo.component_type+'-'+componentInfo.id" class="col-12 collapse">
                <div class="box-element margin-top-30 margin-bottom-0"> 
                    <div class="form-group">
                        <textarea v-model="componentInfo.comments" class="form-control input-style" :id="'add-component-to-list-comments'+componentInfo.id" rows="3"></textarea>
                    </div>
                    <div class="row margin-top-20 margin-bottom-20 justify-content-center">
                        <div class="col-auto">
                            <button data-toggle="collapse" :href="'#component-message-'+componentInfo.component_type+'-'+componentInfo.id" class="btn btn-primary btn-lg text-uppercase">
                                Save
                            </button>
                        </div>
                    </div>                   
                </div>
            </div>
        </div>

        <create-bucket-list></create-bucket-list>

        <div class="table-responsive-md margin-top-20">
            <table class="table table-hover" style="min-width: 585px">
                <thead>
                <tr>
                    <th scope="col">List name</th>
                    <th scope="col">Items</th>
                    <th scope="col">Created</th>
                    <th scope="col" class="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="bucketList in data" :key="bucketList.id+'-'+bucketList.updated_at">
                    <td><a class="link-style" href="#" @click.prevent="openModal('edit', {bucketListId: bucketList.id})">{{ bucketList.name }}</a></td>
                    <td>{{ bucketList.userBucketListComponents.length }}</td>
                    <td>{{ [ bucketList.created_at, "YYYY-MM-DD HH:mm:ss" ] | moment("MMM d, YYYY") }}</td>
                    <td class="text-right">
                        <span v-if="!componentInfo">
                            <a href="#" @click.prevent="openModal('edit', {bucketListId: bucketList.id})" class="margin-right-10 d-inline-block" ><i class="material-icons">edit</i></a>
                            <a href="#" @click.prevent="openModal('share', {bucketListId: bucketList.id, shareLink: getShareLink(bucketList.uuid)})" class="margin-right-10 d-inline-block"><i class="material-icons">share</i></a>
                            <a href="#" @click.prevent="confirmDelete(bucketList)" class="d-inline-block"><i class="material-icons">delete</i></a>
                        </span>
                        <span v-if="componentInfo">
                            <a href="#" @click.prevent="addComponentToBucketList(bucketList.id)" class="d-inline-block"><i class="material-icons">playlist_add</i></a>
                        </span>
                    </td>
                </tr>
                <tr v-if="data.length === 0">
                    <td colspan="4"><p><strong>You don't have bucket lists. Create one and share it!</strong></p></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import ShareModal from "../modals/ShareModal";
    import EditBucketListModal from "../modals/EditBucketListModal";
    import CreateBucketList from "./CreateBucketList";
    import Loader from "./Loader";
    import moment from 'moment';

    export default {
        components: { CreateBucketList, Loader },

        props: {
            component: {
                default() { return {component_type: null, component_id: null, comments: ""}; },
                validator(value) {
                    return (
                        _.isObject(value) &&
                        _.has(value, "component_type") &&
                        _.has(value, "component_id")
                    );
                }
            }
        },

        created() {
            this.initialize();
        },

        computed: {
            data() {
                return this.$store.getters["UserBucketList/data"];
            },
            componentInfo() {
                if(!this.validComponentProvided || this.$store.getters["Data/loading"])
                    return null;

                let comp = this.$store.getters["Data/get"+this.component.component_type+"ById"](this.component.component_id);

                if(comp)
                    return _.assign(comp, this.component);

                return null
            },
            globalSettings() {
              return this.$globalVariables.get('settings');
            },
            userSettings() {
              return this.$store.getters["UserSettings/settings"];
            },
            loading() {
                return this.$store.getters["UserBucketList/loading"] || this.$store.getters["Data/loading"];
            },
            validComponentProvided() {
                return this.component.component_type && this.component.component_id;
            }
        },

        methods: {
            initialize() {
                this.$store.dispatch("Data/initializeData");
                this.$store.dispatch("UserBucketList/initializeData");
            },

            async addComponentToBucketList(bucketListId) {
                const data = _.assign({}, this.componentInfo);
                data.user_bucket_list_id = bucketListId;

                try {
                    const response = await this.$store.dispatch("UserBucketList/createBucketListComponent", data);

                    if(response)
                    {
                        this.$emit("component-added-to-list", response);
                        this.component.component_type = null;
                        this.component.component_id = null;
                        this.component.commnets = "";
                    }
                } catch (ignore) {}
            },

            confirmDelete(bucketList) {
                const vm = this;

                vm.$modal.show('dialog',{
                    title: 'Are you sure you want to delete following list?',
                    text: 'List: <strong>'+ bucketList.name +'</strong><br>Items: <strong>'+bucketList.userBucketListComponents.length+'</strong><br>Created: <strong>'+moment(bucketList.created_at).format("MMM d, YYYY")+'</strong>',
                    class: "delete-confirmation-dialog",
                    buttons: [
                        {
                            title: 'Cancel',
                            class: 'btn btn-primary btn-lg text-uppercase'
                        },
                        {
                            title: 'Delete',
                            default: true,
                            class: 'btn btn-danger btn-lg text-uppercase',
                            handler: () => {
                                vm.$store.dispatch("UserBucketList/deleteBucketList", bucketList.id);
                                vm.$modal.hide('dialog');
                            }
                        },
                    ]
                })
            },

            getShareLink(bucketListUuid) {
                const {
                    globalSettings: { whiteLabel: { domain: whiteLabelDomain } },
                    userSettings: { uuid } = {}
                } = this;

                return "https://" + whiteLabelDomain + (uuid ? "/" + uuid : "") + "/bucket-list/" + bucketListUuid;
            },

            openModal(modal, props = {}) {
                let modalComponent = null;
                let options = {};

                switch (modal)
                {
                    case "edit":
                        modalComponent = EditBucketListModal;
                        options.width = "700px";
                        break;
                    case "share":
                        modalComponent = ShareModal;
                        break;
                }

                this.$modal.show(modalComponent, props, _.assign({
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                }, options));
            }
        },
    }
</script>

<style lang="scss">
    @import "./../../../sass/components/delete-confirmation-dialog";
</style>