<script>
    import { vueSlideoutPanelService } from 'vue2-slideout-panel'
    import HotelSidebar from "./../../components/sidebars/HotelSidebar"
    import HotelsPage from "./../../pages/Hotels";

    export default {
        extends: HotelsPage,
        template: HotelsPage,
        computed: {
            currentUser() {
                return this.$store.getters["Users/getByToken"](this.$route.params.token);
            },
        },
        methods: {
            markerClicked($event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo) {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: HotelSidebar,
                    cssClass: "slideout-bg-gray",
                    props: {
                        slug: customMarkerInfo.hotel.slug,
                        showGetQuote: !!this.currentUser.settings.show_get_quote,
                        showShareButton: !!this.currentUser,
                    }
                });
            },
        },
    }
</script>