'use strict';

import Scrollspy from 'vue2-scrollspy';
import Snotify, {SnotifyPosition} from 'vue-snotify';
import Vue from 'vue';
import Vue2Filters from 'vue2-filters'
import VCalendar from 'v-calendar';
import * as VueGoogleMaps from "vue2-google-maps";
import VueModal from 'vue-js-modal'
import VueMoment from 'vue-moment';
import VueRouter from 'vue-router';
import VueYouTubeEmbed from 'vue-youtube-embed'

Vue.use(Scrollspy);

Vue.use(Snotify, {
    maxOnScreen: 3,
    toast: {
        bodyMaxLength: 2000,
        buttons: [],
        position: SnotifyPosition.centerBottom,
        timeout: 6000,
    }
});

Vue.use(Vue2Filters);

import 'v-calendar/lib/v-calendar.min.css';
Vue.use(VCalendar, {
    firstDayOfWeek: 2,  // Monday
});

Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyAZHXIyn_S_5dXBf43W3HDIcbLC1N55ZOU",
        libraries: "places" // necessary for places input
    }
});

Vue.use(VueModal, {dynamic: true, dialog: true});

Vue.use(VueMoment);

Vue.use(VueRouter);

Vue.use(VueYouTubeEmbed);
