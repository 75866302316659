<template>
    <div id="map-sidebar">
        <slideout padding="300" menu="#menu" panel="#panel" :toggleSelectors="['.toggle-button']">
            <nav :style="navStyle" id="menu" class="rounded-left">
                <div class="map-sidebar-header">
                    <div class="text-right margin-bottom-20">
                        <a href="#" @click.prevent="clearAllSelections()" class="link-style d-inline-block">Clear all <i class="material-icons">clear_all</i></a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" v-for="(menuItem, menuItemKey) in menu">
                        <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-left">{{ menuItem.title }}</h3>
                        <ul>
                            <li v-for="(option, optionKey) in menuItem.options">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" :id="menuItemKey+':'+optionKey" @click="optionToggle(option.identifier, !option.selected)" v-model="option.selected">
                                    <label class="form-check-label" :for="menuItemKey+':'+optionKey">
                                        {{ option.name }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <main id="panel">
                <button class="toggle-button"><i class="material-icons">arrow_forward</i></button>
                <google-map :style="mapStyle" :zoom="zoom" :position="position" :markers="markers" @marker-clicked="markerClicked"></google-map>
            </main>
        </slideout>
    </div>
</template>

<script>
    import Slideout from 'vue-slideout'
    import GoogleMap from "../maps/GMap"

    function validMarkers(markers)
    {
        const filteredOptionMarkers = _.filter(markers, (marker) => {
            return (
                _.isObject(marker) &&
                _.has(marker, "position") &&
                _.isObject(marker.position) &&
                _.has(marker.position, "lat") &&
                _.isNumber(parseFloat(marker.position.lat)) &&
                _.has(marker.position, "lng") &&
                _.isNumber(parseFloat(marker.position.lng))
            );
        });

        return markers.length === filteredOptionMarkers.length;
    }

    function validMenuItems(menuItems)
    {
        const filteredMenuItems = _.filter(menuItems, (menuItem) => {
            return (
                _.isObject(menuItem) &&

                _.has(menuItem, "title") &&
                _.isString(menuItem.title) &&

                _.has(menuItem, "options") &&
                _.isArray(menuItem.options) &&
                validOptions(menuItem.options)
            );
        });

        return menuItems.length === filteredMenuItems.length;
    }

    function validOptions(options)
    {
        const filteredOptions = _.filter(options, (option) => {
            return (
                _.isObject(option) &&
                _.has(option, "identifier") &&
                _.has(option, "name") &&
                _.isString(option.name) &&
                _.has(option, "selected") &&
                _.isBoolean(option.selected) &&
                _.has(option, "markers") &&
                _.isArray(option.markers) &&
                validMarkers(option.markers)
            );
        });

        return options.length === filteredOptions.length;
    }

    export default {
        components: { Slideout, GoogleMap },

        props: {
            mapStyle: {
                default: "",
                type: String
            },
            menu: {
                default() { return [] },
                validator(value) {
                    // [
                    //      {
                    //          title: AAA,
                    //          options: [
                    //              {
                    //                  identifier: (???)
                    //                  name: AAA
                    //                  selected: Boolean
                    //                  markers: [{position: {lat: ###, lng: ###} }, ...]
                    //              }
                    //          ]
                    //      },
                    //      ...
                    // ]
                    return _.isArray(value) && validMenuItems(value);
                }
            },
            navStyle: {
                default: "",
                type: String
            },
            position: {
                required: true,
                validator(value) {
                    // {lat: ###, lng: ###}
                    return (
                        _.isObject(value) &&
                        _.has(value, "lat") &&
                        _.has(value, "lng") &&
                        _.isNumber(value.lat) &&
                        _.isNumber(value.lng)
                    );
                }
            },
            zoom: {
                type: Number,
                default: 5
            }
        },

        computed: {
            markers() {
                return _.reduce(this.menu, function(carry, menuItem, key) {
                    _.forEach(menuItem.options, (option) => {
                        if(option.selected)
                            carry = carry.concat(option.markers);
                    });

                    return carry;
                }, []);
            }
        },

        methods: {
            clearAllSelections() {
                this.$emit("clear-all-selections");
            },
            markerClicked($event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo) {
                this.$emit("marker-clicked", $event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo);
            },
            optionToggle(identifier, value) {
                this.$emit("option-toggle", identifier, value);
            }
        }
    }
</script>

<style lang="scss" type="text/scss">
    /** Unique Index Styles **/
</style>