'use strict';

import VueRouter from 'vue-router';

import Destination from "./../pages/Destination";
import Destinations from "./../pages/Destinations";
import HomePage from "./../pages/Home";
import HotelPage from "./../pages/Hotel";
import HotelsPage from "./../pages/Hotels";
import MapPage from "./../pages/Map";
import UserPage from "./../pages/User";
import NotFoundPage from "./../pages/404";
import BucketList from "../pages/BucketList";

const router = new VueRouter({
    linkExactActiveClass: 'active',
    mode: 'history',
    routes: [
        {
            path: '/:token',
            component: UserPage,
            children: [
                {
                    path: '',
                    component: HomePage,
                    name: 'home'
                },
                {
                    path: 'bucket-list/:bucketListUuid',
                    component: BucketList,
                    name: 'bucket-list'
                },
                {
                    path: 'destinations/:slug',
                    component: Destination,
                    name: 'destination'
                },
                {
                    path: 'destinations',
                    component: Destinations,
                    name: 'destinations'
                },
                {
                    path: 'hotels/:slug',
                    component: HotelPage,
                    name: 'hotel'
                },
                {
                    path: 'hotels',
                    component: HotelsPage,
                    name: 'hotels'
                },
                {
                    path: 'map',
                    component: MapPage,
                    name: 'map'
                },
            ],
        },
        {path: '*', component: NotFoundPage}
    ],
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
});

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});

export default router;