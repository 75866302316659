<template>
    <div class="jumbotron jumbotron-small text-center" :style="'background: url('+pictureUrl+') no-repeat;'">
        <!--<div class="dark-layer"></div>-->
        <div class="white position-absolute top-0 row h-100 w-100 justify-content-center align-items-center text-center margin-right-left-0">
            <dots-loader class="jumbo-info" v-if="loading" :dots-amount="4" :loader-style="'font-size: 40px'"></dots-loader>
            <div v-if="!loading" class="jumbo-info">
                <h1 class="text-uppercase font-xl white text-shadow">{{ title }}</h1>
                <h2 v-if="subtitle" class="text-uppercase font-lg white text-shadow">{{ subtitle }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
    import DotsLoader from "./../misc/DotsLoader"

    export default {
        components: {DotsLoader},

        props: {
            loading: {
                type: Boolean,
                default: false
            },
            pictureUrl: {
                type: String,
                required: true
            },
            subtitle: {
                type: String
            },
            title: {
                type: String,
                required: true
            },
        }
    }

</script>