<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Add your Review</h3>
                <form method="POST" @submit.prevent="onSave">
                    <div class="row">
                        <div class="col form-group">
                            <star-rating :id="'star-rating'" :star-size="30" v-model="form.rating"></star-rating>
                            <p class="help text-danger margin-0" v-if="$v.form.rating.$error">Please provide a rating.</p>
                        </div>
                    </div>
                    <div class="row margin-top-20">
                        <div class="col form-group">
                            <label for="review-content" class="font-sm">Review</label>
                            <textarea name="review" id="review-content" class="form-control input-style" type="text" v-model="form.review"></textarea>
                            <p class="help text-danger margin-0" v-if="$v.form.review.$error">This field is required</p>
                        </div>
                    </div>
                    <div class="row margin-top-20 margin-bottom-20 justify-content-center">
                        <div class="col-auto">
                            <button type="submit" class="btn btn-primary btn-lg text-uppercase" :disabled="isLoading">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {minValue, maxValue, required} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";
    import StarRating from 'vue-star-rating';
    import {HttpResponseStatus} from "../../services/Http/HttpResponse";

    export default {
        components: {StarRating},
        mixins: [validationMixin],
        props: {
            hotelId: Number
        },

        computed: {
            currentUser() {
                const token = this.$route.params.token;

                return this.$store.getters['Auth/user'] || this.$store.getters['Users/getByToken'](token);
            },
        },
        data() {
            return {
                isLoading: false,
                form: {
                    hotel_id: this.hotelId,
                    review: '',
                    rating: 0,
                }
            }
        },
        methods: {
            async onSave() {
                this.$v.form.$touch();

                if (this.$v.form.$invalid)
                    return false;

                this.isLoading = true;
                
                const response = await this.$httpClient.request('POST', '/api/hotel-reviews/save', {review: this.form}, true);

                this.isLoading = false;

                if (response.status !== HttpResponseStatus.OK)
                    return false;

                this.$emit('addedReview', response.data);
                this.$emit('close');

                return true;
            },
        },

        validations: {
            form: {
                rating: {required, minValue: minValue(1), maxValue: maxValue(5)},
                review: {required}
            }
        },
    }
</script>

<style lang="scss" type="text/scss">

</style>