<script>
    import DestinationPage from "./../../pages/Destination"
    import SpecialOfferModal from "./../../components/modals/SpecialOfferModal"

    export default {
        extends: DestinationPage,
        template: DestinationPage,

        computed: {
            currentUser() {
                return this.$store.getters["Users/getByToken"](this.$route.params.token);
            }
        },

        methods: {
            openSpecialOfferModal(promotion) {
                this.$modal.show(SpecialOfferModal, {
                    promotion,
                    showGetQuote: !!this.currentUser.settings.show_get_quote,
                }, {
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                })
            }
        }
    }
</script>
