<template>
    <div id="hotels-page">

        <!-- Hotel Section and Filters -->
        <section v-if="!loading" class="section-style margin-top-100">
            <div data-aos="fade-left"
                 data-aos-offset="100"
                 data-aos-once="true"
                 data-aos-duration="500">

                 <div class="container padding-right-left-0">
                    <h1 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Hotels collection</h1>
                 </div>

                <!-- Tabs View -->
                <div class="container padding-right-left-0 mb-2">
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <ul class="nav tabs-custom" id="pills-tab" role="tablist">
                                <li class="nav-item mr-4">
                                    <a class="active d-block link-style" id="grid-view-tab" data-toggle="pill" href="#grid-view" role="tab" aria-controls="grid-view" aria-selected="true">
                                        List view  <i class="material-icons">apps</i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="d-block link-style" id="map-view-tab" data-toggle="pill" href="#map-view" role="tab" aria-controls="map-view" aria-selected="false">
                                        Map view <i class="material-icons">map</i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>


                <!-- Hotel List with Filters and Map -->
                <div class="tab-content" id="pills-tabContent">

                    <!-- Tab Hotel List and Filters -->
                    <div class="tab-pane fade show active" id="grid-view" role="tabpanel" aria-labelledby="grid-view-tab">

                        <!-- Filters -->
                        <div class="container padding-right-left-0">
                            <div class="row">
                                <div class="col margin-bottom-20">
                                    <a class="link-style d-inline-block" data-toggle="collapse" href="#filters-container" role="button" aria-expanded="false" aria-controls="section-filter">Filter hotels <i class="material-icons">keyboard_arrow_down</i></a>
                                </div>
                            </div>
                        </div>
                        <div id="filters-container" class="section-filter padding-right-left-0 bg-white collapse">
                            <div class="section-filter-cont">
                                <div class="container">
                                    <div class="row">
                                        <div class="col text-right margin-bottom-20">
                                            <a href="#" @click.prevent="clearFilter" class="link-style d-inline-block">Clear all <i class="material-icons">clear_all</i></a>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-12 col-sm-12 col-md-6 form-group">
                                            <label class="font-sm">Hotel name</label>
                                            <i class="material-icons">business</i>
                                            <input v-model="filters.name" type="text" class="form-control input-style">
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-12 col-sm-12 col-md-6 form-group form-group-multi">
                                            <label class="font-sm">Destinations</label>
                                            <multiselect
                                                    v-model="filters.destinations"
                                                    :options="destinations"
                                                    :multiple="true"
                                                    :close-on-select="false"
                                                    :clear-on-select="false"
                                                    label="name"
                                                    placeholder=""
                                                    track-by="id">
                                                <template slot="tag" slot-scope="{ values, search, isOpen }">
                                                    <div v-for="(value, index) in values" v-if="!isOpen">
                                                        <div class="multiselect__tag input-style-multiples-elements">
                                                            <div class="d-flex" v-if="index !== 0">{{ value.name }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-12 col-sm-12 col-md-6 form-group form-group-multi">
                                            <label class="font-sm">Regions</label>
                                            <multiselect
                                                    v-model="filters.regions"
                                                    :options="regions"
                                                    :multiple="true"
                                                    :close-on-select="false"
                                                    :clear-on-select="false"
                                                    label="name"
                                                    placeholder=""
                                                    track-by="id">
                                                <template slot="tag" slot-scope="{ values, search, isOpen }">
                                                    <div v-for="(value, index) in values" v-if="!isOpen">
                                                        <div class="multiselect__tag input-style-multiples-elements">
                                                            <div class="d-flex" v-if="index !== 0">{{ value.name }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Hotel List -->
                        <div class="container padding-right-left-0 margin-top-40">
                            <div class="row justify-content-center" style="min-height: 520px;">
                                <div class="col-12 col-md-6 col-lg-4" v-for="hotel in filteredHotels">
                                    <div class="box-element bg-white box-shadow d-flex flex-column rounded">
                                        <div class="box-element-image">
                                            <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="zoom-image rounded-top">
                                                <span class="zoom-image-hover"></span>
                                                <img class="width-100 rounded-top" :src="hotel.main_thumbnail_picture_path">
                                            </router-link>
                                        </div>
                                        <div class="box-element-info">
                                            <div class="row">
                                                <div class="col">
                                                    <h3 class="font-md">
                                                        <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="link-style font-weight-bold">
                                                            {{ hotel.name }}
                                                        </router-link>
                                                    </h3>
                                                    <p>{{ hotel.destination.name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="box-element-link text-center mt-auto">
                                            <router-link :to="{name: 'hotel', params: {slug: hotel.slug}}" class="link-style">View hotel</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tab Map -->
                    <div class="tab-pane fade" id="map-view" role="tabpanel" aria-labelledby="map-view-tab">
                        <div class="container padding-right-left-0 margin-top-40">
                            <div class="row">
                                <div class="col-12">
                                    <div class="overflow-hidden box-shadow rounded">
                                        <section-interactive-map :menu="menu" :position="mapPosition" @clear-all-selections="clearAllSelections" @option-toggle="optionToggle" @marker-clicked="markerClicked"></section-interactive-map>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <loader class="margin-top-120" v-if="loading" :radius="10" :stroke="2"></loader>
    </div>
</template>

<script>
    import { vueSlideoutPanelService } from 'vue2-slideout-panel'
    import HotelSidebar from "./../components/sidebars/HotelSidebar"
    import BucketListModal from "./../components/modals/BucketListModal"
    import SectionInteractiveMap from "./../components/sections/InteractiveMap"
    import Loader from "./../components/misc/Loader"
    import Multiselect from 'vue-multiselect'

    export default {
        components: { SectionInteractiveMap, Loader, Multiselect },

        created() {
            this.initialize();
        },

        computed: {
            currentUser() {
                return this.$store.getters["Auth/user"];
            },
            groupedDestinations() {
                if(this.destinations.length === 0)
                    return [];

                return _.groupBy(this.destinations, (destination) => {
                    return destination.region_id
                });
            },
            filteredHotels() {
                let hotels = [];
                const vm = this;

                _.forEach(this.hotels, (hotel) => {
                    if(vm.filters.name.length > 0 && !_.includes(hotel.name.toLowerCase(), vm.filters.name.toLowerCase()))
                        return;

                    if(vm.filters.destinations.length > 0 && !_.includes(_.map(vm.filters.destinations, 'id'), hotel.destination.id))
                        return;

                    if(vm.filters.regions.length > 0 && !_.includes(_.map(vm.filters.regions, 'id'), hotel.destination.region.id))
                        return;

                    hotels.push(hotel);
                });

                return hotels;
            },
            mapPosition() {
                return this.$globalVariables.get("settings.googleMaps.defaultMapPosition");
            },
            menu() {
                if(this.groupedDestinations.length === 0)
                    return [];

                return _.map(this.groupedDestinations, (groupedDestinations) => {

                    const options = _.map(groupedDestinations, (destination) => {
                        const markers = _.map(destination.hotels, (hotel) => {
                            return {
                                id: hotel.id,
                                hotel: hotel,
                                position: {lat: hotel.geo_latitude, lng: hotel.geo_longitude},
                            };
                        });

                        return {
                            identifier: destination.id,
                            name: destination.name + " [" + destination.hotels.length + "]",
                            selected: destination.selected,
                            markers: markers
                        }
                    });

                    return {
                        title: groupedDestinations[0].region.name,
                        options: options,
                    }
                })
            },
            regions() {
                return _.map(this.groupedDestinations, (destinations) => {
                    return destinations[0].region;
                });
            },
        },

        data() {
            return {
                hotels : [],
                destinations : [],
                loading: false,
                filters: {
                    destinations: [],
                    name: "",
                    regions: [],
                },
            }
        },

        methods: {
            clearAllSelections() {
                _.forEach(this.destinations, (destination) => {
                    destination.selected = false;
                });
            },

            clearFilter() {
                this.filters.destinations = [];
                this.filters.regions = [];
                this.filters.name = "";
            },

            async fetchDestinations() {
                this.destinations = _.map(this.$store.getters["Data/destinations"], (dest) => {
                    dest.selected = true;
                    return dest;
                });
            },

            async fetchHotels() {
                this.hotels = this.$store.getters["Data/hotels"];
            },

            async initialize() {
                this.loading = true;

                await this.$store.dispatch("Data/initializeData");
                this.fetchDestinations();
                this.fetchHotels();

                this.loading = false;
            },

            markerClicked($event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo) {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: HotelSidebar,
                    cssClass: "slideout-bg-gray",
                    props: {
                        slug: customMarkerInfo.hotel.slug,
                        showGetQuote: !!this.currentUser,
                        showShareButton: !!this.currentUser,
                    }
                });
            },

            openMyBucketList(identifier) {
                this.$modal.show(BucketListModal, {
                    component: {
                        type: "Hotel",
                        identifier
                    }
                }, {
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                })
            },

            optionToggle(identifier, value) {
                _.find(this.destinations, (dest) => {return dest.id == identifier}).selected = value;
            }
        },
    }
</script>
