'use strict';

import store from "./../../store";
import {HttpResponse, HttpResponseStatus} from "./HttpResponse";

export default {
    install(Vue, options) {
        Vue.prototype.$httpClient = {
            /**
             *
             * @param method
             * @param url
             * @param data
             * @param showNotification
             * @param hideNotificationOnError
             * @returns {Promise<HttpResponse>}
             */
            async request(method, url, data, showNotification, hideNotificationOnError) {
                let axiosResponse;
                let response = new HttpResponse();

                try {
                    axiosResponse = await axios({
                        method,
                        url,
                        data
                    });

                    response.status = axiosResponse.data.status || HttpResponseStatus.OK;
                } catch (err) {
                    axiosResponse = err.response;
                    response.status = HttpResponseStatus.ERROR;
                }

                /**
                 * Handle un authenticated errors
                 */
                if(axiosResponse.status === 401)
                {
                    await store.dispatch("Auth/unAuthenticated");
                    return response;
                }

                response.data = _.get(axiosResponse, 'data.data.data') || null;
                response.messages = _.get(axiosResponse, 'data.messages') || [];
                response.meta = _.get(axiosResponse, 'data.data.meta') || null;
                response.rawResponse = axiosResponse;
                response.responseCode = axiosResponse.status;

                if(HttpResponseStatus.OK === response.status && showNotification && response.messages.length)
                {
                    let body, title = "";

                    if(response.messages.length)
                        body = response.messages.join("<br/>");
                    else
                        body = "Good job! Keep working hard.";

                    title = "Success";

                    let html = `
                        <div class="snotifyToast__title"><strong>${title}</strong></div>
                        <div class="snotifyToast__body" style="width: 100%">${body}</div>
                    `;

                    Vue.prototype.$snotify.success("", {html: html});
                }

                if(HttpResponseStatus.ERROR === response.status && !hideNotificationOnError)
                {
                    let body, title = "";

                    if(response.messages.length)
                        body = response.messages.join("<br/>");
                    else
                        body = "Whoops! Internal error. Please try again later";

                    if(response.responseCode === 422)
                        title = "Validation Error";
                    else
                        title = "Error";

                    let html = `
                        <div class="snotifyToast__title"><strong>${title}</strong></div>
                        <div class="snotifyToast__body" style="width: 100%">${body}</div>
                    `;

                    Vue.prototype.$snotify.error("", {html});
                }

                return response;
            }
        };
    },
}