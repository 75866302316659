<template>
    <a href="#" @click.prevent="openShareModal" class="font-md link-style d-inline-block"><i class="material-icons">share</i></a>
</template>

<script>
    import ShareModal from "./../modals/ShareModal"

    export default {
        props: {
            component: {
                required: true,
                validator(value) {
                    // {component_type: string, component_id: ###}
                    return (
                        _.isObject(value) &&
                        _.has(value, "component_type") &&
                        _.has(value, "component_id")
                    );
                }
            },
        },
        created() {
            this.$store.dispatch("UserSettings/initializeData");
        },
        computed: {
            globalSettings() {
                return this.$globalVariables.get('settings');
            },
            userSettings() {
                return this.$store.getters["UserSettings/settings"];
            },
            componentData() {
                return this.$store.getters[`Data/get${this.component.component_type}ById`](this.component.component_id);
            }
        },
        methods: {
            openShareModal() {
                this.$modal.show(ShareModal, {shareLink: this.getShareLink(this.component.component_type, this.componentData.slug)}, {
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                })
            },
            getShareLink(componentType, slug) {

                const uuid = _.get(this.userSettings, 'uuid');
                const whiteLabelDomain = _.get(this.globalSettings, 'whiteLabel.domain');
                const token = _.get(this.$route, 'params.token');

                const baseSection = "https://" + whiteLabelDomain;
                const uuidSection = (uuid ? "/" + uuid : token ? "/" + token : "");
                const uriSection = "/" + componentType.toLowerCase() + 's' + "/" + slug;

                return  baseSection + uuidSection + uriSection;
            }
        }
    }
</script>