import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'


Bugsnag.start({
    apiKey: 'c03d4b39802c40f8838355e914353763',
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ['production']
})

const bugsnagClient = Bugsnag.getPlugin('vue')
bugsnagClient.installVueErrorHandler(Vue)

export default bugsnagClient;
