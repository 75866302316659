<template>
    <div class="three-dots-loader" :style="loaderStyle">
        <span v-for="index in dotsAmount" :style="dotStyle">.</span>
    </div>
</template>

<script>
    export default {
        props: {
            loaderStyle: {
                type: String,
                default: ""
            },
            dotStyle: {
                type: String,
                default: ""
            },
            dotsAmount: {
                type: Number,
                default: 3
            },
        },
    }
</script>

<style lang="scss">
    // Components
    @import "./../../../sass/components/three-dots-loader";
</style>