<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="close">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content margin-top-10">
                <bucket-list @open-component-page="close" @sent="close"></bucket-list>
            </div>
        </div>
    </div>
</template>

<script>
    import BucketList from "./../misc/BucketList"

    export default {
        components: { BucketList },

        methods: {
            close() {
                this.$emit('close');
            }
        }
    }
</script>