<template>
    <div class="container">
        <div class="row margin-bottom-40">
            <div class="col-12">
                <h3><span class="text-uppercase">{{ destination.name }}</span> &nbsp&nbsp <small>Destination</small></h3>
            </div>
        </div>

        <!-- THUMBNAIL AND COMMENTS -->
        <div class="row">
            <div class="col-md-6 margin-bottom-40">
                <div class="card destination-thumbnail">
                    <img class="card-img-top" :src="destination.main_thumbnail_picture_path" :alt="destination.name+'_thumbnail'">
                    <div class="card-body">

                        <h5 class="card-title text-uppercase font-weight-bold margin-top-20 margin-bottom-20">Comments</h5>
                        <p class="card-text">
                            {{ destination.comments }}
                        </p>

                    </div>
                </div>
            </div>

            <div class="col-md-6">

                <!-- DESCRIPTION -->
                <div class="row">
                    <div class="col-12">
                        <h5 class="text-uppercase font-weight-bold margin-bottom-40">Description</h5>
                        <p>{{ destination.description | truncate(300) | stripHtml}}</p>

                        <p class="margin-top-20">
                            <a href="#" class="link-style" @click.prevent="$router.push(baseUrl+'/destinations/'+destination.slug)">Visit destination page <i class="material-icons">chevron_right</i></a>
                        </p>
                    </div>
                </div>

                <!-- HOTELS -->
                <div class="row margin-top-20">
                    <div class="col-12 margin-bottom-40">
                        <h5 class="text-uppercase font-weight-bold">Hotels</h5>
                    </div>

                    <div class="col-12" v-for="hotel in take(destination.hotels, 3)">
                        <div class="media">
                            <a href="javascript:void(0)" class="link-style mr-3"><i class="material-icons">business</i></a>
                            <div class="media-body">
                                <h5 class="mt-0"><a href="#" @click.prevent="$router.push(baseUrl+'/hotels/'+hotel.slug)" class="link-style">{{ hotel.name}}</a></h5>
                                {{ hotel.description | truncate(150) | stripHtml }}
                            </div>
                            <a href="#" @click.prevent="$router.push(baseUrl+'/hotels/'+hotel.slug)" class="link-style align-self-center ml-2"><i class="material-icons">arrow_forward</i></a>
                        </div>

                        <hr>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            destination: Object
        },

        computed: {
            baseUrl() {
                return "/" + this.$route.params.token;
            }
        },

        methods: {
            take(elements, take) {
                return _.take(elements, take);
            }
        }
    }
</script>

<style lang="scss" type="text/scss">
    h5 {
        color: #343a40
    }
</style>