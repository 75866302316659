<template>
    <section>
        <section-header></section-header>

        <div style="min-height: 100vh">
            <router-view></router-view>
        </div>

        <section-footer></section-footer>
    </section>
</template>

<script>
    import SectionFooter from './../components/Footer';
    import SectionHeader from './../components/Header';

    export default {
        components: { SectionHeader, SectionFooter }
    }
</script>