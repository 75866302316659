<template>
    <div>
        <div class="row">
            <div class="col-12">
                <form @submit.prevent="submitForm">
                    <fieldset :disabled="loading">
                        <div class="form-group">
                            <label class="font-sm">List name</label>
                            <loader v-if="loading" class="position-absolute" style="top: 5px; left: -10px; z-index: 9"></loader>
                            <i v-if="!loading" class="material-icons">list</i>
                            <input v-model="bucketList.name" name="list-name" type="text" class="form-control input-style" aria-describedby="New List">
                            <input type="submit" value="Save" class="btn btn-light btn-append btn-append-before"/>
                            <button @click.prevent="cancel" type="button" class="btn btn-light btn-append">Cancel</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
        <form-errors :errors="errors"></form-errors>
    </div>
</template>

<script>
    import Loader from "../misc/Loader"
    import FormErrors from "../misc/FormErrors"

    export default {
        components: { FormErrors, Loader },

        created() {
            this.initialize();
        },

        mounted() {
            this.refreshBucketList();
        },

        props: {
            bucketListId: {
                default: null,
                validator(value) {
                    return (
                        _.isNull(value) ||
                        _.isString(value) ||
                        _.isNumber(value)
                    );
                },
            },
            clearOnSuccess: {
                type: Boolean,
                default: true,
            }
        },

        computed: {
            loading() {
                return this.$store.getters["UserBucketList/loading"];
            }
        },

        data() {
            return {
                bucketList: this.getEmptyBucketList(),
                errors: []
            };
        },

        methods: {
            initialize() {
                this.$store.dispatch("UserBucketList/initializeData");
            },

            cancel() {
                this.errors = [];
                this.$emit("cancelled");
            },

            getEmptyBucketList() {
                return {
                    id: null,
                    name: ""
                };
            },

            refreshBucketList() {
                let response = this.getEmptyBucketList();

                if(this.bucketListId)
                    response = _.assign(response, this.$store.getters["UserBucketList/getById"](this.bucketListId));

                this.bucketList = response;
            },

            async saveBucketList() {
                const method = this.bucketList.id ? "updateBucketList" : "createBucketList";
                const event = this.bucketList.id ? "edited" : "created";

                const response = await this.$store.dispatch("UserBucketList/"+method, this.bucketList);

                if(response)
                {
                    this.$emit(event, response);

                    if(this.clearOnSuccess)
                        this.bucketList = this.getEmptyBucketList();
                }
            },

            submitForm() {
                this.validateForm();

                if(this.errors.length === 0)
                    this.saveBucketList();
            },

            validateForm() {
                this.errors = [];

                if(!this.bucketList.name)
                    this.errors.push("The name field is required.");
            },
        },

        watch: {
            bucketListId(newValue, oldValue) {
                this.refreshBucketList();
            }
        }
    }
</script>