<template>
    <footer v-if="loading || currentUser" class="container-fluid text-center margin-top-0">

        <dots-loader v-if="loading" class="dots-loader"></dots-loader>

        <div v-if="!loading">
            <!-- Copyright Section -->
            <div id="footer-copyright" class="row text-center">
                <div class="col-12 copyright font-sm">
                    <p class="white">Copyright &#169; {{ new Date().getFullYear() }} {{ currentUser.travelAgency.name }}. All rights reserved.</p>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
    import { mapActions } from "vuex"
    import DotsLoader from "../../components/misc/DotsLoader";

    export default {
        components: { DotsLoader },

        created() {
            this.initialize()
        },

        computed: {
            loading() {
                return this.$store.getters["Users/loadingUser"](this.$route.params.token);
            },
            currentUser() {
                return this.$store.getters["Users/getByToken"](this.$route.params.token);
            }
        },

        methods: {
            ...mapActions({
                fetchUser: "Users/fetchUserByToken"
            }),

            initialize() {
                this.fetchUser({token: this.$route.params.token});
            }
        }
    }
</script>

<style lang="scss" type="text/scss">
    .dots-loader {
        margin: auto;
        width: 20px;
        font-size: 40px;
        line-height: 41px;
        padding-bottom: 15px;
    }
</style>