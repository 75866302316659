<template>
    <div class="sidebar">
        <div class="sidebar-header">
            <button class="close" v-on:click.prevent="$emit('closePanel')">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="sidebar-body">
            <get-quote :component-id="componentId" :component-type="componentType" @quoteSent="$emit('closePanel')"></get-quote>
        </div>
        <div v-if="!$globalVariables.get('settings.whiteLabel.is_white_label')" class="sidebar-footer d-none d-sm-block">
            <span class="default-letter-spacing text-uppercase font-weight-bold font-md">Speak to our experts +1 (800) 231-4095</span>
        </div>
    </div>
    
</template>

<script>
    import GetQuote from "../misc/GetQuote";

    export default {
        components: { GetQuote },

        props: {
            componentId: {
                default: null,
                type: Number|String
            },
            componentType: {
                default: "",
                type: String
            },
        },

        data() {
            const hSpacing = '20px';
            return {
                selectedDate: {
                    start: new Date(2018, 0, 9),
                    end: new Date(2018, 0, 18)
                },
                formats: {
                    title: 'MMMM YYYY',
                    weekdays: 'W',
                    navMonths: 'MMM',
                    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'], // Only for `v-date-picker`
                    data: ['MMM DD, YYYY'] // For attribute dates
                },
                themeStyles: {
                    wrapper: {
                        background: '#fff',
                        color: '#fafafa',
                        border: '0',
                        borderRadius: '5px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 6px 20px 0 rgba(0, 0, 0, 0.13)'
                    },
                    header: {
                        padding: `20px ${hSpacing}`,
                        fontWeight: '900',
                        fontSize: '1rem',
                    },
                    weekdays: {
                        color: '#868686', // New color
                        fontWeight: '600', // And bolder font weight
                        padding: `20px ${hSpacing} 5px ${hSpacing}`,
                    },
                    weeks: {
                        padding: `0 ${hSpacing} ${hSpacing} ${hSpacing}`,
                    },
                    dayContent: {
                        fontSize: '0.9rem'
                    }
                }
            }
        },
        methods: {
        }
    };

</script>