<template>
    <div class="loader">
        <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" :r="radius" fill="none" :stroke-width="stroke" stroke-miterlimit="10"/>
        </svg>
    </div>
</template>

<script>
    export default {
        props: {
            radius: {
                type: Number,
                default: 5
            },
            stroke: {
                type: Number,
                default: 1
            },
        },
    }
</script>