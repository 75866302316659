'use strict';

const NOT_INITIALIZED = 0;
const INITIALIZATION_IN_PROGRESS = 1;
const INITIALIZED = 2;

export default {
    NOT_INITIALIZED,
    INITIALIZATION_IN_PROGRESS,
    INITIALIZED
};