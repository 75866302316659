<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">{{ promotion.name }}</h3>
                <div class="row">
                    <div class="col-12">
                        <ul class="row">
                            <li class="col-12 col-sm-6 margin-bottom-20">
                                <div class="font-md d-block">
                                    <i class="material-icons margin-right-10 align-baseline">flight_takeoff</i>
                                    <span class="d-inline-block">
                                        Travel window<br>
                                        <strong v-if="promotion.travel_window_from && promotion.travel_window_to">{{ [ promotion.travel_window_from, "MM/DD/YYYY" ] | moment("MMM D, YYYY") }} - {{ [ promotion.travel_window_to, "MM/DD/YYYY" ] | moment("MMM D, YYYY") }}</strong>
                                        <strong v-if="!promotion.travel_window_from || !promotion.travel_window_to">-</strong>
                                    </span>
                                </div>
                            </li>
                            <li class="col-12 col-sm-6 margin-bottom-20">
                                <div class="font-md d-block">
                                    <i class="material-icons margin-right-10 align-baseline">date_range</i>
                                    <span class="d-inline-block">
                                        Booking window<br>
                                        <strong v-if="promotion.booking_window_from && promotion.booking_window_to">{{ [ promotion.booking_window_from, "MM/DD/YYYY" ] | moment("MMM D, YYYY") }} - {{ [ promotion.booking_window_to, "MM/DD/YYYY" ] | moment("MMM D, YYYY") }}</strong>
                                        <strong v-if="!promotion.booking_window_from || !promotion.booking_window_to">-</strong>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <p class="text-justify separate-lines">{{ promotion.description }}</p>
                        <p v-if="!$globalVariables.get('settings.whiteLabel.is_white_label')"><a :href="companyInfo.tollFreePhone.getURI()"><strong>Call us today: {{ companyInfo.tollFreePhone.formatInternational() }}</strong></a></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <div v-if="showGetQuote" class="margin-top-20 margin-bottom-20">
                            <get-quote-button :component-id="promotion.hotel_id" :component-type="'Hotel'"  @click.native="$emit('close')"></get-quote-button>
                        </div>
                    </div>
                </div>
                <hr class="d-block">
                <div class="row">
                    <div class="col-12">
                        <ul>
                            <li class="margin-bottom-20">
                                <div class="font-sm d-block">
                                    <span class="d-inline-block">
                                        Terms and Conditions<br>
                                        <strong class="text-justify">{{ promotion.terms_and_conditions }}</strong>
                                    </span>
                                </div>
                            </li>
                            <li class="margin-bottom-20">
                                <div class="font-sm d-block">
                                    <span class="d-inline-block">
                                        Blackout Policy<br>
                                        <strong class="text-justify">{{ promotion.black_out_dates_policy }}</strong>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GetQuoteButton from "../misc/GetQuoteButton";

    export default {
        components: { GetQuoteButton },

        props: {
            promotion: {
                required: true,
                validator(value) {
                    return (
                        _.isObject(value) &&
                        _.has(value, "name") &&
                        _.has(value, "description") &&
                        _.has(value, "terms_and_conditions") &&
                        _.has(value, "black_out_dates_policy") &&
                        _.has(value, "travel_window_from") &&
                        _.has(value, "travel_window_to") &&
                        _.has(value, "booking_window_from") &&
                        _.has(value, "booking_window_to")
                    );
                }
            },
            showGetQuote: {
                default: true,
                type: Boolean
            },
        },

        computed: {
            companyInfo() {
                return this.$store.getters["Company/companyInfo"];
            }
        }
    }
</script>

<style lang="scss" type="text/scss">
    /** Unique Index Styles **/
</style>