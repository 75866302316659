'use strict';

const data = {};

export default {
    install(Vue, options) {
        Vue.prototype.$globalVariables = {
            set(key, value) {
                _.set(data, key, value);
            },
            get(key, def = null) {
                return _.get(data, key, def);
            }
        };
    },
}