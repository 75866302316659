import Vue from "vue"
import {HttpResponseStatus} from "./../../services/Http/HttpResponse";
import init from "./../helpers/initializationValues"

/**
 * State
 */
const state = {
    data: [],
    loading: false,
    initialized: init.NOT_INITIALIZED,
};

/**
 * Getters
 */
const getters = {
    loading: state => {
        return state.loading;
    },
    initialized: state => {
        return state.initialized;
    },
    data: state => {
        return _.cloneDeep(state.data);
    },
    getById: (state, getters) => id => {
        let response = null;

       const index = getComponentIndexFromId(getters.data, id);
       if(index !== null)
           response = _.cloneDeep(getters.data[index]);

        return response;
    },
    getByUuid: (state, getters) => uuid => {
        let response = null;

       const index = _.findIndex(getters.data, {uuid: uuid});
       
       if(index !== null)
           response = _.cloneDeep(getters.data[index]);

        return response;
    },
};

/**
 * Mutations
 */
const mutations = {
    DELETE_USER_BUCKET_LIST_FROM_DATA(state, index) {
        state.data.splice(index, 1);
        state.data = _.sortBy(state.data, ['position', 'created_at']);
    },
    INSERT_USER_BUCKET_LIST_TO_DATA(state, userBucketList) {
        state.data.unshift(userBucketList);
        state.data = _.sortBy(state.data, ['position', 'created_at']);
    },
    PUSH_USER_BUCKET_LIST_TO_DATA(state, payload) {
        state.data.push(payload.userBucketList);
        state.data = _.sortBy(state.data, ['position', 'created_at']);
    },
    REPLACE_USER_BUCKET_LIST_TO_DATA(state, payload) {
        state.data[payload.index] = payload.userBucketList;
        state.data = _.sortBy(state.data, ['position', 'created_at']);
    },
    UPDATE_DATA(state, data) {
        state.data = _.isArray(data) ? data : [];
    },
    UPDATE_INITIALIZED_STATE(state, value) {
        state.initialized = value;
    },
    UPDATE_LOADING_STATE(state, status) {
        state.loading = Boolean(status);
    }
};

/**
 * Actions
 */
const actions = {
    async fetchData(context, cache)
    {
        cache = typeof cache === "undefined" ? true : Boolean(cache);

        if(cache && context.getters.initialized === init.INITIALIZED)
            return;

        let records = [];

        try {
            let response = await Vue.prototype.$httpClient.request("get", "/api/bucket-lists", {});
            records = HttpResponseStatus.OK === response.status ? response.data : [];
        } catch (ignore) {
            records = [];
        }

        context.commit("UPDATE_DATA", records);
    },
    async initializeData(context, cache)
    {
        if(
            context.getters.initialized === init.INITIALIZATION_IN_PROGRESS ||
            context.getters.initialized === init.INITIALIZED
        )
            return;

        context.commit("UPDATE_LOADING_STATE", true);
        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZATION_IN_PROGRESS);

        await context.dispatch("fetchData", cache);

        context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZED);
        context.commit("UPDATE_LOADING_STATE", false);
    },
    async createBucketList(context, newBucketList)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let returnValue = false;

        try {
            const response = await Vue.prototype.$httpClient.request("post", "/api/bucket-lists", newBucketList, true);
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if(data)
            {
                context.commit("INSERT_USER_BUCKET_LIST_TO_DATA", data);
                returnValue = data;
            }
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return returnValue;
    },
    async createBucketListComponent(context, newBucketListComponent)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let returnValue = false;

        try {
            const response = await Vue.prototype.$httpClient.request("post", "/api/bucket-list-components", newBucketListComponent, true);
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if(data)
            {
                returnValue = data;

                let bucketListIndex = getComponentIndexFromId(context.getters.data, data.user_bucket_list_id);
                let bucketList = context.getters.data[bucketListIndex];
                if(bucketList)
                {
                    bucketList.userBucketListComponents.push(data);

                    context.commit("REPLACE_USER_BUCKET_LIST_TO_DATA", {
                        index: bucketListIndex,
                        userBucketList: bucketList,
                    });
                }
            }
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return returnValue;
    },
    async deleteBucketList(context, id)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let deleted = false;

        try {
            await Vue.prototype.$httpClient.request("delete", "/api/bucket-lists/"+id, {}, true);

            let bucketListIndex = getComponentIndexFromId(context.getters.data, id);

            if(bucketListIndex !== null)
                context.commit("DELETE_USER_BUCKET_LIST_FROM_DATA", bucketListIndex);

            deleted = true;
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return deleted;
    },
    async deleteBucketListComponent(context, payload)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let deleted = false;

        try {
            await Vue.prototype.$httpClient.request("delete", "/api/bucket-list-components/"+payload.bucketListComponentId, {}, true);

            let bucketListIndex = getComponentIndexFromId(context.getters.data, payload.bucketListId);
            let bucketList = context.getters.data[bucketListIndex];

            let bucketListComponentIndex = getComponentIndexFromId(bucketList.userBucketListComponents, payload.bucketListComponentId);
            bucketList.userBucketListComponents.splice(bucketListComponentIndex, 1);

            context.commit("REPLACE_USER_BUCKET_LIST_TO_DATA", {index: bucketListIndex, userBucketList: bucketList});

            deleted = true;
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return deleted;
    },
    async updateBucketList(context, newBucketList)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let returnValue = false;

        try {
            const response = await Vue.prototype.$httpClient.request("patch", "/api/bucket-lists/"+newBucketList.id, newBucketList, true);
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if(data)
            {
                let oldBucketListIndex = getComponentIndexFromId(context.getters.data, newBucketList.id);

                if(oldBucketListIndex !== null)
                {
                    context.commit("REPLACE_USER_BUCKET_LIST_TO_DATA", {index: oldBucketListIndex, userBucketList: data});
                    returnValue = data;
                }
            }
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return returnValue;
    },
    async updateBucketListComponent(context, bucketListComponent)
    {
        context.commit("UPDATE_LOADING_STATE", true);
        let returnValue = false;

        try {
            const response = await Vue.prototype.$httpClient.request("patch", "/api/bucket-list-components/"+bucketListComponent.id, bucketListComponent, true);
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if(data)
            {
                let bucketListIndex = getComponentIndexFromId(context.getters.data, bucketListComponent.user_bucket_list_id);
                let bucketList = context.getters.data[bucketListIndex];

                let bucketListComponentIndex = getComponentIndexFromId(bucketList.userBucketListComponents, bucketListComponent.id);
                bucketList.userBucketListComponents[bucketListComponentIndex] = data;

                context.commit("REPLACE_USER_BUCKET_LIST_TO_DATA", {index: bucketListIndex, userBucketList: bucketList});
                returnValue = data;
            }
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return returnValue;
    },
    async updateBucketListComponentsOrder(context, payload) {
        context.commit("UPDATE_LOADING_STATE", true);
        let returnValue = false;

        try {
            const sortResponse = await Vue.prototype.$httpClient.request("post", "/api/actions/sort-bucket-list-components", {
                user_bucket_list_id: payload.userBucketListId,
                user_bucket_list_component_ids: payload.userBucketListComponentIds
            }, true);

            if(HttpResponseStatus.OK === sortResponse.status)
            {
                const response = await Vue.prototype.$httpClient.request("get", "/api/bucket-lists/"+payload.userBucketListId, {});
                const data = HttpResponseStatus.OK === response.status ? response.data : null;

                if(data)
                {
                    let bucketListIndex = getComponentIndexFromId(context.getters.data, payload.userBucketListId);

                    context.commit("REPLACE_USER_BUCKET_LIST_TO_DATA", {index: bucketListIndex, userBucketList: data});
                    returnValue = true;
                }
            }
        } catch (ignore) {}

        context.commit("UPDATE_LOADING_STATE", false);
        return returnValue;
    },

    async getBucketList(context, payload) {
        let returnValue = false;

        const response = await Vue.prototype.$httpClient.request("get", "/api/bucket-lists/"+payload.userBucketListId, {});

        if(HttpResponseStatus.OK === response.status)
        {
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if(data)
            {
                context.commit("PUSH_USER_BUCKET_LIST_TO_DATA", {userBucketList: data});

                returnValue = true;
            }

            return returnValue;
        }
    },

    async getBucketListByUuid(context, payload) {
        let returnValue = false;

        const response = await Vue.prototype.$httpClient.request("get", "/api/bucket-lists/uuid/"+payload.bucketListUuid, {});

        if(HttpResponseStatus.OK === response.status)
        {
            const data = HttpResponseStatus.OK === response.status ? response.data : null;

            if(data)
            {
                context.commit("PUSH_USER_BUCKET_LIST_TO_DATA", {userBucketList: data});

                returnValue = true;
            }

            return returnValue;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

/////////////////////////////////////////////
/////////////// HELPERS /////////////////////
/////////////////////////////////////////////
function getComponentIndexFromId(list, id) {
    let listIndex = null;

    _.forEach(list, (component, index) => {
        if(component.id === id)
        {
            listIndex = index;
            return false;
        }
    });

    return listIndex;
}