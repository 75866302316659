<template>
    <div id="destinations-page">

        <!-- Content Section -->
        <section v-if="!loading" class="section-style margin-top-100">
            <div data-aos="fade-left"
                 data-aos-offset="100"
                 data-aos-once="true"
                 data-aos-duration="500">

                <div class="container padding-right-left-0">
                    <h1 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Destinations</h1>
                </div>

                <!-- Tabs View -->
                <div class="container padding-right-left-0 mb-2">
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <ul class="nav tabs-custom" id="pills-tab" role="tablist">
                                <li class="nav-item mr-4">
                                    <a class="active link-style d-block" id="grid-view-tab" data-toggle="pill" href="#grid-view" role="tab" aria-controls="grid-view" aria-selected="true">List view <i class="material-icons">apps</i></a>
                                </li>
                                <li class="nav-item">
                                    <a class="d-block link-style" id="map-view-tab" data-toggle="pill" href="#map-view" role="tab" aria-controls="map-view" aria-selected="false">Map view <i class="material-icons">map</i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <!-- Destination List and Map -->
                <div class="tab-content" id="pills-tabContent">

                    <!-- Tab Destination List -->
                    <div class="tab-pane fade show active" id="grid-view" role="tabpanel" aria-labelledby="grid-view-tab">
                        <div class="container padding-right-left-0 margin-top-40">
                            <div style="min-height: 520px;">
                                <div class="row justify-content-center" v-for="destinations in groupedDestinations">
                                    <h2 class=" col-12 bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">{{ destinations[0].region.name }}</h2>
                                    <div class="col-12 col-md-6 col-lg-4" :key="key" v-for="(destination, key) in destinations">
                                        <div class="box-element bg-white box-shadow d-flex flex-column rounded">
                                            <div class="box-element-image">
                                                <router-link :to="{name: 'destination', params: {slug: destination.slug}}" class="zoom-image rounded-top">
                                                    <span class="zoom-image-hover"></span>
                                                    <img class="width-100 rounded-top" :src="destination.main_thumbnail_picture_path">
                                                </router-link>
                                            </div>
                                            <div class="box-element-info">
                                                <div class="row">
                                                    <div class="col">
                                                        <h3 class="font-md">
                                                            <router-link :to="{name: 'destination', params: {slug: destination.slug}}" class="link-style font-weight-bold">
                                                                {{ destination.name }}
                                                            </router-link>
                                                        </h3>
                                                        <p>{{ destination.hotel_count }} Hotel<span v-if="destination.hotel_count > 1">s</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="box-element-link text-center mt-auto">
                                                <router-link :to="{name: 'destination', params: {slug: destination.slug}}" class="link-style">View destination</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tab Map -->
                    <div class="tab-pane fade" id="map-view" role="tabpanel" aria-labelledby="map-view-tab">
                        <div class="container padding-right-left-0 margin-top-40">
                            <div class="row">
                                <div class="col-12">
                                    <div class="overflow-hidden box-shadow rounded">
                                        <section-interactive-map :menu="menu" :position="mapPosition" @clear-all-selections="clearAllSelections" @option-toggle="optionToggle" @marker-clicked="markerClicked"></section-interactive-map>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <loader class="margin-top-120" v-if="loading" :radius="10" :stroke="2"></loader>
    </div>
</template>

<script>
    import { vueSlideoutPanelService } from 'vue2-slideout-panel';
    import HotelSidebar from "../components/sidebars/HotelSidebar"
    import SectionInteractiveMap from "../components/sections/InteractiveMap"
    import Loader from "./../components/misc/Loader"

    export default {
        components: { SectionInteractiveMap, Loader },

        created() {
            this.initialize();
        },

        computed: {
            currentUser() {
                return this.$store.getters["Auth/user"];
            },
            groupedDestinations() {
                if(this.destinations.length === 0)
                    return [];

                return _.groupBy(this.destinations, (destination) => {
                    return destination.region_id
                });
            },
            mapPosition() {
                return this.$globalVariables.get("settings.googleMaps.defaultMapPosition");
            },
            menu() {
                if(this.groupedDestinations.length === 0)
                    return [];

                return _.map(this.groupedDestinations, (groupedDestinations) => {

                    const options = _.map(groupedDestinations, (destination) => {
                        const markers = _.map(destination.hotels, (hotel) => {
                            return {
                                id: hotel.id,
                                hotel: hotel,
                                position: {lat: hotel.geo_latitude, lng: hotel.geo_longitude},
                            };
                        });

                        return {
                            identifier: destination.id,
                            name: destination.name + " [" + destination.hotels.length + "]",
                            selected: destination.selected,
                            markers: markers
                        }
                    });

                    return {
                        title: groupedDestinations[0].region.name,
                        options: options,
                    }
                })
            },
        },

        data() {
            return {
                destinations : [],
                loading: false,
            }
        },

        methods: {
            clearAllSelections() {
                _.forEach(this.destinations, (destination) => {
                    destination.selected = false;
                });
            },

            initialize() {
                this.fetchDestinations();
            },

            async fetchDestinations() {
                this.loading = true;

                await this.$store.dispatch("Data/initializeData");
                this.destinations = _.map(this.$store.getters["Data/destinations"], (dest) => {
                    dest.selected = true;
                    dest.hotel_count = dest.hotels.length + _.filter(this.$store.getters["Data/destinations"], { parent_id: dest.id }).reduce((count, _dest) => count + _dest.hotels.length, 0);

                    return dest;
                });

                this.loading = false;
            },

            markerClicked($event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo) {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: HotelSidebar,
                    cssClass: "slideout-bg-gray",
                    props: {
                        slug: customMarkerInfo.hotel.slug,
                        showGetQuote: !!this.currentUser,
                        showShareButton: !!this.currentUser,
                    }
                });
            },

            optionToggle(identifier, value) {
                _.find(this.destinations, (dest) => {return dest.id == identifier}).selected = value;
            }
        }
    }
</script>


<style lang="scss">
    .marker-popup-content {
        color: black;
        max-width: 200px;
        max-height: 300px;
        overflow: auto;
    }
</style>
