<template>
    <a href="#" role="button" aria-pressed="true" @click.prevent="toggleBucketList" class="btn btn-primary btn-bucket btn-lg text-uppercase">
        <i class="material-icons" :style="{color: includedInBucketList ? '#dc3545 !important' : '#6c757d !important'}">favorite</i> Add to my bucket
    </a>
</template>

<script>
    import getLS from "./../../../services/localStorageBucketList";

    const listenerKey = _.uniqueId("white-label-bucket-list-");

    export default {
        props: {
            component: {
                required: true,
                validator(value) {
                    // {component_type: string, component_id: ###}
                    return (
                        _.isObject(value) &&
                        _.has(value, "component_type") &&
                        _.has(value, "component_id")
                    );
                }
            },
            radius: {
                type: Number,
                default: 5
            },
            stroke: {
                type: Number,
                default: 1
            },
        },

        mounted() {
            this.updateIncludedInBucketList();
            getLS().addListener(this.lsKeyListener, this.updateIncludedInBucketList);
        },

        beforeDestroy() {
            getLS().removeListener(this.lsKeyListener);
        },

        data() {
            return {
                lsKeyListener: _.uniqueId("white-label-add-to-my-bucket-list-"),
                includedInBucketList: false
            };
        },

        methods: {
            toggleBucketList() {
                if(!this.includedInBucketList)
                    getLS().pushComponent(this.component);
                else
                    getLS().removeComponent(_.findIndex(getLS().load(), {component_type: this.component.component_type, component_id: this.component.component_id}));
            },

            updateIncludedInBucketList() {
                this.includedInBucketList = _.findIndex(getLS().load(), {component_type: this.component.component_type, component_id: this.component.component_id}) > -1
            }
        },

        watch: {
            component: {
                deep: true,
                handler() {
                    this.updateIncludedInBucketList();
                }
            }
        }
    }
</script>