'use strict';

export default {
    assignFromDefault(defaultObject, dataObject) {
        return _.assign(
            defaultObject,
            _.pick(dataObject, _.keys(defaultObject))
        );
    },

    nl2br(str, is_xhtml) {
        //  discuss at: http://phpjs.org/functions/nl2br/
        // original by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // improved by: Philip Peterson
        // improved by: Onno Marsman
        // improved by: Atli Þór
        // improved by: Brett Zamir (http://brett-zamir.me)
        // improved by: Maximusya
        // bugfixed by: Onno Marsman
        // bugfixed by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        //    input by: Brett Zamir (http://brett-zamir.me)
        //   example 1: nl2br('Kevin\nvan\nZonneveld');
        //   returns 1: 'Kevin<br />\nvan<br />\nZonneveld'
        //   example 2: nl2br("\nOne\nTwo\n\nThree\n", false);
        //   returns 2: '<br>\nOne<br>\nTwo<br>\n<br>\nThree<br>\n'
        //   example 3: nl2br("\nOne\nTwo\n\nThree\n", true);
        //   returns 3: '<br />\nOne<br />\nTwo<br />\n<br />\nThree<br />\n'

        // Break Line
        const breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display
        str = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');

        // Tab Character
        const tabTag = '&nbsp;&nbsp;&nbsp;&nbsp;'; // Adjust comment to avoid issue on phpjs.org display
        str = (str + '').replace(/(\t)/g, tabTag);

        return str;
    },
}