<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">{{ hotelRoom.name }}</h3>
                <p class="text-justify separate-lines" v-html="hotelRoomDescription"></p>
                <div v-if="showGetQuote" class="text-center margin-top-40">
                    <get-quote-button :component-id="hotelRoom.hotel_id" :component-type="'Hotel'" @click.native="$emit('close')"></get-quote-button>
                </div>
                <div class="row gallery justify-content-center">
                    <div class="col-md-3 col-6" v-for="(picture, index) in hotelRoom.hotelRoomPictures">
                        <a href="#" @click.prevent="openModal(index)" class="zoom-image rounded">
                            <span class="zoom-image-hover"></span>
                            <img :src="picture.path" class="width-100 rounded" :alt="hotelRoom.name">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CarouselModal from "./CarouselModal"
    import GetQuoteButton from "../misc/GetQuoteButton"
    import utils from "./../../services/utils"

    export default {
        components: { GetQuoteButton },

        computed: {
            picturesForCarousel() {
                return _.map(this.hotelRoom.hotelRoomPictures, (picture) => {
                    return `<img src="${picture.path}" class="width-100" alt="${this.hotelRoom.name}" />`;
                })
            },
            hotelRoomDescription() {
                if(!this.hotelRoom) return "";

                return utils.nl2br(this.hotelRoom.description);
            }
        },

        props: {
            hotelRoom: {
                required: true,
                validator(value) {
                    return (
                        _.isObject(value) &&
                        _.has(value, "name") &&
                        _.has(value, "description") &&
                        _.has(value, "hotelRoomPictures") &&
                        _.isArray(value.hotelRoomPictures)
                    );
                }
            },
            showGetQuote: {
                default: true,
                type: Boolean
            },
        },

        methods: {
            openModal(index) {
                let options = {};
                /*const width = window.innerWidth - 50;
                const height = window.innerHeight - 50;
                const computedWidth = height * 1.5 - 82;
                const computedHeight = width / 1.5 + 82;

                if(computedHeight > height)
                    options.height = height;
                else
                    options.height = computedHeight;

                if(computedWidth < width)
                    options.width = computedWidth;
                else
                    options.width = width;*/
                
                options.width = "70%";

                this.$modal.show(CarouselModal, {
                    data: this.picturesForCarousel,
                    index
                }, _.assign({
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                }, options))
            }
        },
    }
</script>

<style lang="scss" type="text/scss">
    /** Unique Index Styles **/
</style>