export default {
    methods: {
        baseUrl(path = "") {
            let response = "/" + this.$route.params.token;

            if(path)
            {
                path = path.replace(/^\//, '');
                response += "/" + path;
            }

            return  response;
        }
    }
}