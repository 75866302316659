import Vue from "vue"
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {HttpResponseStatus} from "../../services/Http/HttpResponse"

/**
 * State
 */
const state = {
    data: {},
    loadingUser: {},
};

/**
 * Getters
 */
const getters = {
    loading: state => {
        return Boolean(_.findKey(state.loadingUser));
    },
    loadingUser: state => token => {
        if(_.has(state.loadingUser, token))
            return state.loadingUser[token];

        return false;
    },
    data: state => {
        return _.cloneDeep(state.data);
    },
    getByToken: state => token => {
        if(_.has(state.data, token))
        {
            let user = _.cloneDeep(state.data[token]);
            let settings = user.userSetting.payload.white_label_website;
            user.settings = settings;
            user.settings.token = token;

            if(!settings.show_company_name)
                user.travelAgency.name = "Travel Agent Junkie";

            if(!settings.show_email)
                user.memberEmails = [];

            if(!settings.show_logo)
                user.travelAgency.logo = "";

            if(!settings.show_phone_number)
                user.memberPhones = [];

            user.full_name = user.first_name + " " + (user.middle_name ? user.middle_name + " " : "") + user.last_name;
            user.defaultEmail = _.find(user.memberEmails, {'default': 1});
            user.defaultPhone = _.find(user.memberPhones, {'default': 1});
            user.settings.show_get_quote = user.settings.show_get_quote && user.defaultEmail;

            return user;
        }

        return null;
    },
};

/**
 * Mutations
 */
const mutations = {
    UPDATE_DATA_BY_TOKEN(state, payload) {
        Vue.set(state.data, payload.token, payload.data);
    },
    UPDATE_LOADING_USER(state, payload) {
        if(!payload.token)
            return;

        Vue.set(state.loadingUser, payload.token, Boolean(payload.status));
    },
};

/**
 * Actions
 */
const actions = {
    async fetchUserByToken(context, payload)
    {
        let success = false;

        if(!payload.token)
            return success;

        const cache = _.get(payload, "cache", true);

        if(cache && (context.getters.getByToken(payload.token) || context.getters.loadingUser(payload.token)))
            return;

        context.commit("UPDATE_LOADING_USER", {token: payload.token, status: true});

        try {
            const response = await Vue.prototype.$httpClient.request("get", "/api/users/"+payload.token, {});

            if (HttpResponseStatus.OK === response.status)
            {
                context.commit("UPDATE_DATA_BY_TOKEN", {token: payload.token, data: response.data});
                success = true;
            }
        } catch (e) { }

        context.commit("UPDATE_LOADING_USER", {token: payload.token, status: false});
        return success;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}