import AbstractDriver from "./abstract-driver";
import { isFunction} from "lodash";
import postscribe from "postscribe";
import q from "q";

const key = 'UA-4147184-1';

class GoogleAnalytics extends AbstractDriver
{
    constructor()
    {
        super();

        this.initializing = null;
    }

    init(config)
    {
        if(this._exists())
        {
            gtag('config', key, {
                'send_page_view': false,
            });

            if (config.user_id)
                gtag('set', 'user_id', config.user_id)
        }
        else
        {
            this.initializing = q.defer();

            this._loadScript(config);
        }
    }

    async pageView()
    {
        const initialized = await this.initializing.promise;

        if(initialized && this._exists(true))
        {
            gtag('event', 'page_view');
        }
    }

    async lead()
    {
        const initialized = await this.initializing.promise;

        if(initialized && this._exists(true))
        {
            gtag('event', 'generate_lead');
        }
    }

    _exists(showErrors = false)
    {
        if (typeof gtag === 'undefined' || !isFunction(gtag))
        {
            if (showErrors)
                console.error("gtag is not initialized");

            return false;
        }

        return true;
    }

    _loadScript(config)
    {
        if (this._exists())
            return false;

        /**
         * Facebook recommends to add this code at the end of the head tag of the application but we are going to
         * load it asynchronously.
         *
         * See: https://developers.facebook.com/docs/facebook-pixel/implementation
         */
        postscribe(document.querySelector('head'), `
            <!-- Global site tag (gtag.js) - Google Analytics -->
            <script async src="https://www.googletagmanager.com/gtag/js?id=${key}"></script>
            <script>
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
            </script>
             <!-- Global site tag (gtag.js) - Google Analytics -->

        `, {
            done: () => {
                this.initializing.resolve(true);

                this.init(config);
            },
            error: () => {
                this.initializing.resolve(false);
            }
        });

        return true;
    }
}

export default GoogleAnalytics;