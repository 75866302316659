import init from "./../helpers/initializationValues"
import bugsnagClient from "../../services/bugsnag";

/**
 * State
 * @type {{loading: boolean, user: null}}
 */
const state = {
    user: null,
    loading: false,
    initialized: init.NOT_INITIALIZED,
};

/**
 * Getters
 * @type {{fullName: getters.fullName}}
 */
const getters = {
    initialized: state => {
        return state.initialized;
    },
    loading: state => {
        return state.loading;
    },
    user: state => {
        let user = state.user;

        if(user)
        {
            user.full_name = user ? user.first_name + " " + user.last_name : "";
            user.defaultEmail = _.find(user.memberEmails, {'default': 1});
            user.defaultPhone = _.find(user.memberPhones, {'default': 1});
        }

        return user;
    },
};

/**
 * Mutations
 * @type {{UPDATE_USER(*, *): void}}
 */
const mutations = {
    UPDATE_USER(state, payload) {
        state.user = _.isObject(payload) ? payload.user || null : null;

        /**
         * Ser user for bugsnag
         */
        if (state.user)
        {
            let defaultEmail = _.find(state.user.memberEmails, {default: 1});
            let user = {
                id: state.user.id,
                name: state.user.first_name + " " + state.user.last_name,
                username: state.user.username,
                travelAgency: _.get(state.user, 'travelAgency.name')
            };

            if (defaultEmail)
                user.email = defaultEmail.email;

            bugsnagClient.user = user;
        }
    },
    UPDATE_INITIALIZED_STATE(state, value) {
        state.initialized = value;
    },
    UPDATE_LOADING_STATE(state, status) {
        state.loading = Boolean(status);
    }
};

/**
 * Actions
 */
const actions = {
    async fetchUser(context, cache)
    {
        cache = typeof cache === "undefined" ? true : Boolean(cache);

        if(cache && (context.getters.initialized === init.INITIALIZED || context.getters.initialized === init.INITIALIZATION_IN_PROGRESS))
            return;

        context.commit("UPDATE_LOADING_STATE", true);

        if(context.getters.initialized === init.NOT_INITIALIZED)
            context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZATION_IN_PROGRESS);

        try {
            let response = await axios.get("/api/users/me");

            if (response.data)
            {
                context.commit("UPDATE_USER", {user: response.data});

                if(context.getters.initialized === init.INITIALIZATION_IN_PROGRESS)
                    context.commit("UPDATE_INITIALIZED_STATE", init.INITIALIZED);
            }
            else
                context.commit("UPDATE_USER", null);
        } catch (e) {
            context.commit("UPDATE_USER", null);
        }

        context.commit("UPDATE_LOADING_STATE", false);
    },
    async unAuthenticated(context, payload = null) {
        context.commit("UPDATE_USER", null);

        let redirectUrl = "/login";

        if(payload && payload.intendedUrl)
            redirectUrl += "?intended=" + payload.intendedUrl;

        window.location.href = redirectUrl;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}