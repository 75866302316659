<template>
    <div class="sidebar">
        <div class="sidebar-header">
            <button class="close" v-on:click.prevent="closePanel">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="sidebar-body">
            <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Search</h3>
            <form v-on:submit.prevent="">
                <div class="row">
                    <div class="col form-group">
                        <label>Search (Min. input: {{ this.minQueryLength }})</label>
                        <i class="material-icons">search</i>
                        <input required minlength="4" ref="searchInput" v-model="query" type="text" class="form-control input-style" aria-describedby="Search ...">
                    </div>
                </div>
            </form>

            <loader v-if="loading" :radius="10" :stroke="2"></loader>

            <h3 v-if="noResults" class="font-md margin-top-20 margin-bottom-20">No results, try another search criteria...</h3>

             <!--Destinations Results -->
            <div v-if="showDestinations">
                <div class="row">
                    <div class="col">
                        <h3 class="font-md text-center margin-top-20 margin-bottom-20">Destinations</h3>
                    </div>
                </div>
                <ul>
                    <li class="sidebar-item rounded" v-for="destination in filteredDestinations">
                        <a @click.prevent="openPage('/' + getUri('destination', destination.slug))" :href="getLink('destination', destination.slug)" class="link-style d-block">
                            <div class="row">
                                <i class="col-auto material-icons d-none d-md-block">location_on</i>
                                <div class="col">
                                    <h3 class="font-md">{{ destination.name | truncate(50) }}</h3>
                                    <p>{{ destination.description | stripHtml | truncate(100) }}</p>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <!-- Hotel Results -->
            <div v-if="showHotels">
                <div class="row">
                    <div class="col">
                        <h3 class="font-md text-center margin-top-20 margin-bottom-20">Hotels</h3>
                    </div>
                </div>
                <ul>
                    <li class="sidebar-item rounded" v-for="hotel in filteredHotels">
                        <a @click.prevent="openPage('/' + getUri('hotel', hotel.slug))" :href="getLink('hotel', hotel.slug)" class="link-style d-block">
                            <div class="row">
                                <i class="col-auto material-icons d-none d-md-block">business</i>
                                <div class="col">
                                    <h3 class="font-md">{{ hotel.name | truncate(50) }}</h3>
                                    <p>{{ hotel.description | stripHtml | truncate(100) }}</p>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from "./../misc/Loader"

    export default
    {
        components: { Loader },

        created() {
            this.initialize();
        },

        mounted() {
            this.$refs.searchInput.focus();
        },

        computed: {
            globalSettings() {
                return this.$globalVariables.get('settings');
            },

            noResults() {
                return this.shouldFilter && !this.loading && this.filteredHotels.length === 0 && this.filteredDestinations.length === 0;
            },
            filteredDestinations() {
                const vm = this;

                if(!this.shouldFilter) return [];

                return _.filter(vm.destinations, (destination) => {
                    return _.includes(destination.name.toLowerCase(), vm.query.toLowerCase())
                });
            },
            filteredHotels() {
                const vm = this;

                if(!vm.shouldFilter) return [];

                return _.filter(vm.hotels, (hotel) => {
                    return _.includes(hotel.name.toLowerCase(), vm.query.toLowerCase())
                });
            },
            shouldFilter() {
                return !(this.query.length < this.minQueryLength);
            },
            showDestinations() {
                return !this.loading && this.filteredDestinations.length > 0;
            },
            showHotels() {
                return !this.loading && this.filteredHotels.length > 0;
            },
        },

        data() {
            return {
                destinations: [],
                minQueryLength: 4,
                hotels: [],
                loading: false,
                query: "",
            }
        },

        methods: {
            closePanel() {
                this.$emit('closePanel', {});
            },

            async initialize() {
                this.loading = true;
                await this.$store.dispatch("Data/initializeData");
                this.fetchDestinations();
                this.fetchHotels();
                this.loading = false;
            },

            async fetchDestinations() {
                this.destinations = this.$store.getters["Data/destinations"];
            },

            async fetchHotels() {
                this.hotels = this.$store.getters["Data/hotels"];
            },

            openPage(path) {
                this.$router.push({path});
                this.closePanel();
            },
            getUri(componentType, slug) {
                const isWhiteLabel = _.get(this.globalSettings, 'whiteLabel.is_white_label');

                return  (isWhiteLabel ? this.$route.params.token + "/" : "") + componentType.toLowerCase() + 's' + "/" + slug;
            },
            getLink(componentType, slug) {
                const
                    whiteLabelDomain = _.get(this.globalSettings, 'whiteLabel.domain'),
                    isWhiteLabel = _.get(this.globalSettings, 'whiteLabel.is_white_label');

                return (isWhiteLabel ? "https://" + whiteLabelDomain + "/" : '') + this.getUri(componentType, slug);
            }
        },
    };
</script>

<style lang="scss" type="text/scss">
    /** Unique Index Styles **/
</style>