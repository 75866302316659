/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
//Load bugsnag
require('./services/bugsnag');

// Load all plugins
require("./plugins");

// Load all services
require("./services");

// Load all filters
require("./filters");

// Register the main component
Vue.component('application', require('./white-label/Application').default);

import store from "./store"
import router from "./white-label/routes";

const app = new Vue({
    store,
    router
}).$mount('#app');

