import AbstractDriver from "./abstract-driver";
import { isFunction } from "lodash";
import postscribe from "postscribe";

const key = '1052056322079253';

class FacebookPixel extends AbstractDriver
{
    init()
    {
        if(this._exists())
        {
            fbq('init', key);
        }
        else
        {
            this._loadScript();
        }
    }

    pageView()
    {
        if(this._exists(true))
        {
            fbq('track', 'PageView');
        }
    }

    lead()
    {
        if(this._exists(true))
        {
            fbq('track', 'Lead');
        }
    }

    _exists(showErrors = false)
    {
        if (typeof fbq === 'undefined' || !isFunction(fbq))
        {
            if (showErrors)
                console.error("Fbq is not initialized");

            return false;
        }

        return true;
    }

    _loadScript()
    {
        if (this._exists())
            return false;

        /**
         * Facebook recommends to add this code at the end of the head tag of the application but we are going to
         * load it asynchronously.
         *
         * See: https://developers.facebook.com/docs/facebook-pixel/implementation
         */
        postscribe(document.querySelector('head'), `
            <!-- Facebook Pixel Code -->
            <script nonce="WkPQQDHa">
            !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
            document,'script','https://connect.facebook.net/en_US/fbevents.js');</script>
            <noscript><img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=1052056322079253&ev=PageView&noscript=1"
            /></noscript>
            <!-- End Facebook Pixel Code -->
        `, {
            done: () => {
                this.init();
            }
        });

        return true;
    }
}

export default FacebookPixel;
