<template>
    <div class="row">
        <div class="col-12 form-group">
            <div v-if="errors.length" class="alert alert-danger">
                <strong>Please correct the following error(s):</strong>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            errors: {
                type: Array,
                required: true
            },
        },
    }
</script>