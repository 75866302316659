<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content overflow-auto">
                <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Give your testimonial about UJV</h3>
                <form method="POST" @submit.prevent="onSave">
                    <div class="row">
                        <div class="col form-group">
                            <star-rating :id="'star-rating'" :star-size="30" v-model="form.rating"></star-rating>
                            <p class="help text-danger margin-0" v-if="$v.form.rating.$error">Please provide a rating.</p>
                        </div>
                    </div>
                    <div class="row margin-top-20">
                        <div class="col form-group form-group-multi-2">
                            <label class="font-sm">Who do you work with?</label>
                            <i class="material-icons">person_outline</i>
                            <multiselect v-model="form.user_id"
                                         :options="agents"
                                         id="ujv_agent"
                                         label="name"
                                         placeholder=""
                                         track-by="id">
                            </multiselect>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label for="review-content" class="font-sm">Testimonial</label>
                            <textarea name="review" id="review-content" class="form-control input-style" type="text" v-model="form.testimony"></textarea>
                            <p class="help text-danger margin-0" v-if="$v.form.testimony.$error">This field is required and should be at least 30 characters long.</p>
                        </div>
                    </div>
                    <div class="row margin-top-20 margin-bottom-20 justify-content-center">
                        <div class="col-auto">
                            <button type="submit" class="btn btn-primary text-uppercase btn-lg" :disabled="isLoading">
                                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {minLength, minValue, maxValue, required} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";
    import StarRating from 'vue-star-rating';
    import {HttpResponseStatus} from "../../services/Http/HttpResponse";
    import Multiselect from "vue-multiselect";
    import axios from "axios";

    export default {
        components: {StarRating, Multiselect},
        mixins: [validationMixin],
        props: {
            hotelId: Number
        },
        computed: {
            currentUser() {
                const token = this.$route.params.token;

                return this.$store.getters['Auth/user'] || this.$store.getters['Users/getByToken'](token);
            },
        },
        created() {
          this.fetchAgents();
        },
        data() {
            return {
                agents: [],
                isLoading: false,
                form: {
                    user_id: null,
                    testimony: '',
                    rating: 0,
                }
            }
        },
        methods: {
            async fetchAgents() {
                const response = await axios.get("/assets/agents/data.json");

                this.agents = response.data || [];
            },
            async onSave() {
                this.$v.form.$touch();

                if (this.$v.form.$invalid)
                    return false;

                const form = {
                    ...this.form,
                    user_id: this.form.user_id.id,
                };

                this.isLoading = true;
                
                const response = await this.$httpClient.request('POST', '/api/testimonies/save', {testimony: form}, true);

                this.isLoading = false;

                if (response.status !== HttpResponseStatus.OK)
                    return false;

                this.$emit('close');

                return true;
            },
        },
        validations: {
            form: {
                user_id: {required},
                rating: {required, minValue: minValue(1), maxValue: maxValue(5)},
                testimony: {required, minLength: minLength(30)}
            }
        },
    }
</script>

<style lang="scss" type="text/scss">

</style>