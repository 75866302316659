<template>
    <div id="header-section" v-if="loading || currentUser">
        <header class="fixed-top z-index-100">
            <dots-loader v-if="loading" class="dots-loader"></dots-loader>

            <nav v-if="!loading" class="navbar navbar-expand navbar-light bg-white navbar-top-small">
                <div class="navbar-nav d-none d-md-inline-block">
                    <li class="nav-item">
                        <a class="nav-link font-weight-bold font-sm" href="javascript:void(0);">{{ currentUser.full_name }}</a>
                    </li>
                </div>
                <ul class="navbar-nav ml-auto">
                    <li v-if="currentUser.defaultEmail" class="nav-item border border-bottom-0 border-top-0 border-left-0">
                        <a class="nav-link font-sm" :href="'mailto:'+currentUser.defaultEmail.email"><i class="material-icons align-middle">mail_outline</i></a>
                    </li>
                    <li v-if="currentUser.defaultPhone" class="nav-item border border-bottom-0 border-top-0 border-left-0">
                        <a class="nav-link font-sm" :href="'tel:+'+currentUser.defaultPhone.phone_number"><i class="material-icons align-middle">phone</i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link font-sm" href="#" @click.prevent="showPanelSearch"><i class="material-icons align-middle">search</i></a>
                    </li>
                    <li v-if="currentUser.settings.show_get_quote" class="nav-item font-sm d-xl-none">
                        <a class="nav-link font-sm icon-action" href="#" @click.prevent="showPanelQuote"><i class="material-icons align-middle">monetization_on</i></a>
                    </li>
                </ul>
            </nav>

            <nav v-if="!loading" class="navbar navbar-expand-xl navbar-light bg-white">
                <a class="navbar-brand" href="javascript:void(0);">
                    <img style="max-height: 40px; max-width: 320px;" v-if="currentUser.travelAgency.logo" :src="currentUser.travelAgency.logo" :alt="currentUser.travelAgency.name">
                    <span v-if="!currentUser.travelAgency.logo" class="nav-link text-uppercase font-weight-bold font-sm pl-0">{{ currentUser.travelAgency.name }}</span>
                </a>
                <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="sr-only">Toggle navigation</span>
                    <i class="material-icons align-middle">menu</i>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav ml-md-auto">
                        <router-link tag="li" :to="{name: 'home'}" exact class="nav-item">
                            <a class="nav-link text-uppercase font-weight-bold font-sm">Home</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'destinations'}" class="nav-item">
                            <a class="nav-link text-uppercase font-weight-bold font-sm">Destinations</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'hotels'}" class="nav-item">
                            <a class="nav-link text-uppercase font-weight-bold font-sm">Hotels</a>
                        </router-link>
                        <router-link tag="li" :to="{name: 'map'}" class="nav-item">
                            <a class="nav-link text-uppercase font-weight-bold font-sm">Map</a>
                        </router-link>
                        <li class="nav-item">
                            <a href="#" class="nav-link text-uppercase font-weight-bold font-sm position-relative" @click.prevent="showBucketListModal">
                                Bucket List
                                <span v-if="bucketListComponentsCount" class="bucket-list-components-count">{{ bucketListComponentsCount }}</span>
                            </a>
                        </li>
                        <li v-if="currentUser.settings.show_get_quote" class="nav-item nav-bt d-none d-xl-inline-block">
                            <get-quote-button :get-component-from-uri="true"></get-quote-button>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    </div>
</template>

<script>
    import { vueSlideoutPanelService } from 'vue2-slideout-panel';
    import GetQuoteSidebar from "../../components/sidebars/GetQuoteSidebar";
    import SearchSidebar from "../../components/sidebars/SearchSidebar";
    import DotsLoader from "../../components/misc/DotsLoader";
    import GetQuoteButton from "../../components/misc/GetQuoteButton";
    import { mapActions } from "vuex"
    import baseUrl from "./../mixins/baseUrl"
    import BucketListModal from "./modals/BucketListModal";
    import getLS from "../../services/localStorageBucketList";

    export default {
        components: { DotsLoader, GetQuoteButton },

        created() {
            this.initialize()
        },

        mounted() {
            this.updateBucketListComponentsCount();
            getLS().addListener(this.lsKeyListener, this.updateBucketListComponentsCount);
        },

        beforeDestroy() {
            getLS().removeListener(this.lsKeyListener);
        },

        computed: {
            currentUser() {
                return this.$store.getters["Users/getByToken"](this.$route.params.token);
            },
            loading() {
                return this.$store.getters["Users/loadingUser"](this.$route.params.token);
            },
        },

        data() {
            return {
                lsKeyListener: _.uniqueId("white-label-bucket-list-header-link-"),
                bucketListComponentsCount: 0,
            };
        },

        mixins: [baseUrl],

        methods: {
            ...mapActions({
                fetchUser: "Users/fetchUserByToken"
            }),

            initialize() {
                this.fetchUser({token: this.$route.params.token});
            },

            showBucketListModal() {
                this.$modal.show(BucketListModal, {}, {
                    draggable: false,
                    height: 'auto',
                    width: "700px",
                    scrollable: true
                })
            },

            showPanelQuote() {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: GetQuoteSidebar,
                });
            },

            showPanelSearch() {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: SearchSidebar
                });
            },

            updateBucketListComponentsCount() {
                this.bucketListComponentsCount = getLS().load().length;
            }
        }
    };
</script>

<style lang="scss" type="text/scss">
    .bucket-list-components-count {
        color: white;
        position: absolute;
        background-color: #dc3545;
        padding: 2px 4px;
        font-size: 12px;
        line-height: 12px;
        border-radius: 8px;
    }

    .dots-loader {
        margin: auto;
        width: 20px;
        font-size: 40px;
        line-height: 41px;
        padding-bottom: 15px;
    }
</style>