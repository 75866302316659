<script>
    import HotelPage from "../../pages/Hotel";
    import HotelSidebar from "../../components/sidebars/HotelSidebar";
    import { vueSlideoutPanelService } from 'vue2-slideout-panel';
    import SpecialOfferModal from "./../../components/modals/SpecialOfferModal";
    import HotelRoomModal from "./../../components/modals/HotelRoomModal";
    import CarouselModal from "./../../components/modals/CarouselModal";

    export default {
        extends: HotelPage,
        template: HotelPage,

        created() {
            this.initialize();
        },

        computed: {
            currentUser() {
                return this.$store.getters["Users/getByToken"](this.$route.params.token);
            },
        },

        methods: {
            markerClicked($event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo) {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: HotelSidebar,
                    cssClass: "slideout-bg-gray",
                    props: {
                        slug: customMarkerInfo.hotel.slug,
                        showGetQuote: !!this.currentUser.settings.show_get_quote,
                        showShareButton: !!this.currentUser
                    }
                });
            },

            openModal(modal, props = {}) {
                let modalComponent = null;
                let options = {};

                switch (modal)
                {
                    case "special-offer":
                        modalComponent = SpecialOfferModal;
                        options.width = "700px";
                        break;
                    case "hotel-room":
                        modalComponent = HotelRoomModal;
                        options.width = "700px";
                        break;
                    case "carousel":
                        modalComponent = CarouselModal;
                        options.width = "80%";
                        break;
                }

                this.$modal.show(modalComponent, _.assign({}, props, {showGetQuote: !!this.currentUser.settings.show_get_quote}), _.assign({
                    draggable: false,
                    height: 'auto',
                    scrollable: true
                }, options));
            }

        }
    }

</script>
