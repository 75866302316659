<template>
    <div id="map-page">

        <!-- Map Section -->
        <section class="margin-top-100">
            <div class="row margin-0">
                <div class="col-12 padding-0">
                    <div class="overflow-hidden">
                        <section-interactive-map :map-style="'min-height: '+mapHeight+'px'" :nav-style="'min-height: '+mapHeight+'px'" :zoom="4" :menu="menu" :position="mapPosition" @clear-all-selections="clearAllSelections" @option-toggle="optionToggle" @marker-clicked="markerClicked"></section-interactive-map>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    import { vueSlideoutPanelService } from 'vue2-slideout-panel';
    import HotelSidebar from "../components/sidebars/HotelSidebar"
    import SectionInteractiveMap from "./../components/sections/InteractiveMap"

    export default {
        components: { SectionInteractiveMap },

        created() {
            this.initialize();
        },

        computed: {
            currentUser() {
                return !_.get(this.globalSettings, 'whiteLabel.is_white_label')
                    ? this.$store.getters["Auth/user"]
                    : this.$store.getters["Users/getByToken"](this.$route.params.token);
            },
            globalSettings() {
                return this.$globalVariables.get('settings');
            },
            mapHeight() {
                return window.innerHeight - 104; // 104 is the header height
            },
            groupedDestinations() {
                if(this.destinations.length === 0)
                    return [];

                return _.groupBy(this.destinations, (destination) => {
                    return destination.region_id
                });
            },
            mapPosition() {
                return this.$globalVariables.get("settings.googleMaps.defaultMapPosition");
            },
            menu() {
                if(this.groupedDestinations.length === 0)
                    return [];

                return _.map(this.groupedDestinations, (groupedDestinations) => {

                    const options = _.map(groupedDestinations, (destination) => {
                        const markers = _.map(destination.hotels, (hotel) => {
                            return {
                                id: hotel.id,
                                hotel: hotel,
                                position: {lat: hotel.geo_latitude, lng: hotel.geo_longitude},
                            };
                        });

                        return {
                            identifier: destination.id,
                            name: destination.name + " [" + destination.hotels.length + "]",
                            selected: destination.selected,
                            markers: markers
                        }
                    });

                    return {
                        title: groupedDestinations[0].region.name,
                        options: options,
                    }
                })
            },
        },

        data() {
            return {
                destinations : [],
                loading: false,
            }
        },

        methods: {
            clearAllSelections() {
                _.forEach(this.destinations, (destination) => {
                    destination.selected = false;
                });
            },

            initialize() {
                this.fetchDestinations();
            },

            async fetchDestinations() {
                this.loading = true;

                await this.$store.dispatch("Data/initializeData");
                this.destinations = _.map(this.$store.getters["Data/destinations"], (dest) => {
                    dest.selected = true;
                    return dest;
                });

                this.loading = false;
            },

            markerClicked($event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo) {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: HotelSidebar,
                    cssClass: "slideout-bg-gray",
                    props: {
                        slug: customMarkerInfo.hotel.slug,
                        showGetQuote: !!this.currentUser,
                        showShareButton: !!this.currentUser
                    }
                });
            },

            optionToggle(identifier, value) {
                _.find(this.destinations, (dest) => {return dest.id == identifier}).selected = value;
            }
        }
    }
</script>
