<template>
    <div class="modal-content">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                <span aria-hidden="true">
                    <i class="material-icons d-none d-md-inline-block">close</i>
                    <i class="material-icons d-md-none">arrow_back</i>
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-body-content">
                <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Share</h3>
                <form method="POST" @submit.prevent="share">
                    <div class="row">
                        <div class="col form-group">
                            <label class="font-sm">Share link</label>
                            <i class="material-icons">link</i>
                            <input name="share-link" type="text" class="form-control input-style" aria-describedby="Share link" :value="shareLink" readonly>
                            <button
                                    id="copyTooltip"
                                    type="button"
                                    class="btn btn-light btn-append"
                                    @click="copyShareLink"
                                    data-toggle="tooltip"
                                    title="Copied to clipboard!">Copy
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <label class="font-sm" for="share-from-email">From email</label>
                            <i class="material-icons">mail_outline</i>
                            <input
                                    id="share-from-email"
                                    name="share-from-email"
                                    type="email"
                                    class="form-control input-style"
                                    aria-describedby="Share from email"
                                    readonly
                                    v-model="fromEmail">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group form-group-multi">
                            <label class="font-sm">To email address</label>
                            <multiselect
                                    v-model="toEmails"
                                    tag-placeholder="Add this as a recipient email"
                                    placeholder=""
                                    label="name"
                                    track-by="code"
                                    :options="toEmailsOptions"
                                    :multiple="true"
                                    :taggable="true"
                                    :disabled="loading"
                                    @tag="addToEmail">
                                <template slot="tag" slot-scope="props" >
                                    <div class="multiselect__tag input-style-multiples-elements">
                                        <div class="d-flex">
                                            <i class="material-icons">mail_outline</i> {{ props.option.name }}
                                            <i class="material-icons input-style-multiples-elements-cancel" @click="props.remove(props.option)">close</i>
                                        </div>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label class="font-sm" for="share-message">Message</label>
                            <textarea
                                    id="share-message"
                                    name="share-message"
                                    class="form-control input-style"
                                    rows="3"
                                    aria-describedby="Message"
                                    v-model="shareMessage"
                                    :disabled="loading"></textarea>
                        </div>
                    </div>
                    <div class="row margin-top-20 margin-bottom-20 justify-content-center">
                        <div class="col-auto">
                            <button type="submit" class="btn btn-primary text-uppercase btn-lg" :disabled="loading">
                                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Share
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from 'vue-multiselect'
    import {email} from "vuelidate/lib/validators";

    export default {
        components: {Multiselect},

        props: {
          shareLink: String
        },

        computed: {
            currentUser() {
                const token = this.$route.params.token;

                return this.$store.getters['Auth/user'] || this.$store.getters['Users/getByToken'](token);
            },
        },
        data() {
            return {
                fromEmail: '',
                loading: false,
                shareMessage: 'I found something that might interest you.',
                toEmails: [],
                toEmailsOptions: [],
                globalSettings: this.$globalVariables.get("settings"),
            }
        },

        methods: {
            addToEmail(newTag) {
                if (!email(newTag))
                    return;

                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                };

                this.toEmailsOptions.push(tag);
                this.toEmails.push(tag);
            },
            copyShareLink() {
                try {
                    this.$clipboard(this.shareLink);

                    const tooltipBtn = $('#copyTooltip').tooltip('show');

                    setTimeout(() => {
                        tooltipBtn.tooltip('hide');
                    },2000);
                } catch(e) {
                    //TODO: BUGSNAG
                }

            },
            async share() {

                const form = {
                    fromEmail: this.fromEmail,
                    message: this.shareMessage,
                    shareLink: this.shareLink,
                    toEmails: this.toEmails.map(option => option.name),
                    data: {
                        token: this.$route.params.token
                    },
                };

                this.loading = true;

                const response = await this.$httpClient.request('post', 'api/actions/share', form, true);

                if (response.status !== 'OK')
                    return this.loading = false;

                this.$emit('close');
            }
        },

        //region LifeCycle Hooks

        destroyed() {
            $('#copyTooltip').tooltip('dispose');
        },

        mounted() {
            this.fromEmail = _.get(this.currentUser, 'defaultEmail.email') || this.globalSettings.defaultFromEmail;

            $('#copyTooltip').tooltip({trigger: 'manual'});
        }

        //endregion
    }
</script>

<style lang="scss" type="text/scss">
    .multiselect__tag {
        color: #495057;
        background-color:transparent;
        font-size:1rem;
        line-height: 1.5;
        margin:0;
        padding:0
    }

    .multiselect__tags, .multiselect {
        min-height:50px;
    }

    form .form-group .input-style-multiples-elements {
        padding: 0px 5px 0px 5px;
    }
</style>