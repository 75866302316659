<template>
    <div id="hotel-page">
        <page-404 v-if="!hotelFound"></page-404>

        <div v-if="hotelFound">
            <section-banner-big :add-to-my-bucket-component="currentUser ? component : null" :loading="loading" :picture-url="bannerInfo.pictureUrl" :title="bannerInfo.title"  :subtitle="bannerInfo.subtitle"></section-banner-big>

            <div v-if="hotel" class="fixed-spy">

                <div class="container">
                    <div class="row">

                        <!-- Side menu -->
                        <div class="d-none d-lg-block col-lg-3 lateral-menu-cont">
                            <div class="fixed-element">
                                <ul v-scroll-spy-active v-scroll-spy-link class="section-nav lateral-menu-jump">
                                    <li v-if="showReviews">
                                        <a class="font-md link-style d-block">
                                            <i class="material-icons">star</i> <span class="d-none d-lg-inline">Reviews</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="font-md link-style d-block">
                                            <i class="material-icons">subject</i> <span class="d-none d-lg-inline">Overview</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="font-md link-style d-block">
                                            <i class="material-icons">photo</i> <span class="d-none d-lg-inline">Media</span>
                                        </a>
                                    </li>
                                    <!--li>
                                        <a class="font-md link-style d-block">
                                            <i class="material-icons">hotel</i> <span class="d-none d-lg-inline">Rooms and suites</span>
                                        </a>
                                    </li-->
                                    <li>
                                        <a class="font-md link-style d-block">
                                            <i class="material-icons">map</i> <span class="d-none d-lg-inline">Map</span>
                                        </a>
                                    </li>
                                    <add-to-my-bucket-button v-if="!$globalVariables.get('settings.whiteLabel.is_white_label') && currentUser" :component="component"></add-to-my-bucket-button>
                                    <a v-if="!$globalVariables.get('settings.whiteLabel.is_white_label')" :href="companyInfo.tollFreePhone.getURI()" class="font-md link-style text-center margin-top-10 d-none d-lg-block">Call us: <span class="d-inline-block">{{ companyInfo.tollFreePhone.formatInternational() }}</span></a>
                                </ul>
                            </div>
                        </div>

                        <!-- Main content -->
                        <div class="col-lg-9 lateral-main-cont">
                            <div v-scroll-spy="{offset: 200}">

                                <!-- Reviews -->
                                <section class="section-style padding-bottom-0 px-xs-0" v-if="showReviews">
                                    <div class="row">
                                        <div class="col">
                                            <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Reviews</h2>
                                        </div>
                                    </div>
                                    <div class="row margin-bottom-20">
                                        <div class="col text-right" v-if="!!currentUser && !!!$globalVariables.get('settings.whiteLabel.is_white_label')">
                                            <a href="#" class="link-style d-block" @click.prevent="openModal('add-review', {hotelId: hotel.id}, {'closed': onClosedReviewModal})">
                                                Add your review <i class="material-icons">create</i>
                                            </a>
                                        </div>
                                    </div>
                                    <div v-if="!!!isLoadingReviews">
                                        <div class="box-element bg-white box-shadow rounded" v-for="review in approvedReviews">
                                            <div class="box-element-info">
                                                <div class="row margin-top-10">
                                                    <div class="col">
                                                        <star-rating v-model="review.rating" :read-only="true" :show-rating="false" :star-size="30"></star-rating>
                                                        <p class="margin-top-20 margin-bottom-20 text-justify">"{{ review.review }}"</p>
                                                        <h3 class="font-md">{{ review.signature }}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!!!approvedReviews.length">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="box-element bg-blue box-shadow rounded">
                                                    <div class="box-element-info">
                                                        <span v-if="isLoadingReviews" class="spinner-border spinner-border-sm white" role="status" aria-hidden="true"></span>
                                                        <p v-if="!!!isLoadingReviews && !!!approvedReviews.length" class="white">
                                                            <span v-if="!!!currentUser">Log in and </span>
                                                            <span v-if="currentUser">B</span><span v-if="!!!currentUser">b</span>e the first one to add a review!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <!-- Overview -->
                                <section class="section-style px-xs-0">
                                    <div class="row">
                                        <div class="col text-right">
                                            <share-component-button v-if="currentUser" :component="component"></share-component-button>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Description</h2>
                                            <p class="text-justify" v-if="!readModeDescriptionExpanded">{{ hotel.description || "" | stripHtml | truncate(250) }}</p>
                                            <p class="text-justify" v-if="readModeDescriptionExpanded" v-html="hotelDescription"></p>
                                            <a href="#" @click.prevent="readModeDescriptionExpanded = !readModeDescriptionExpanded" class="link-style">
                                                Read
                                                <span v-if="!readModeDescriptionExpanded">more <i class="material-icons">add</i></span>
                                                <span v-if="readModeDescriptionExpanded">less <i class="material-icons">remove</i></span>
                                            </a>
                                        </div>
                                    </div>
                                </section>

                                <!-- Media Section -->
                                <section v-if="galleryPictures.length" class="section-style bg-white section-style-white-lateral px-xs-0">
                                    <div class="container">
                                        <div class="row justify-content-end">
                                            <div class="col-lg-9 lateral-main-cont">
                                                <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Media</h2>
                                                <div class="row gallery justify-content-center">
                                                    <div class="col-md-3 col-6" v-for="(picture, index) in galleryPictures" :key="picture.id">
                                                        <a href="#" @click.prevent="openModal('carousel', {data: picturesForCarousel, index})" class="zoom-image rounded">
                                                            <span class="zoom-image-hover"></span>
                                                            <img :src="picture.path" class="width-100 rounded" alt="">
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                                <!-- Rooms Section -->
                                <!--section v-if="hotel.hotelRooms.length" class="section-style bg-white section-style-white-lateral px-xs-0">
                                    <div class="container">
                                        <div class="row justify-content-end">
                                            <div class="col-lg-9 lateral-main-cont">
                                                <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Rooms</h2>
                                                <div class="box-element-lateral box-shadow rounded" v-for="(room, index) in hotel.hotelRooms" :key="room.id">
                                                    <div class="row" :class="{'flex-sm-row-reverse': index % 2 !== 0}">
                                                        <div class="col-12 col-md-6" :class="{'box-element-lateral-right': index % 2 === 0, 'box-element-lateral-left': index % 2 !== 0}">
                                                            <div class="box-element-lateral-image">
                                                                <a href="#" @click.prevent="openModal('hotel-room', {hotelRoom: room})" class="zoom-image d-flex align-items-center">
                                                                    <span class="zoom-image-hover"></span>
                                                                    <img v-if="room.hotelRoomPictures && room.hotelRoomPictures.length" class="width-100 height-100" :src="room.hotelRoomPictures[0].path" :alt="room.name">
                                                                    <img v-if="room.hotelRoomPictures.length === 0" class="width-100" src="/assets/placeholders/placeholder.png" :alt="room.name">
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-6" :class="{'box-element-lateral-left': index % 2 === 0, 'box-element-lateral-right': index % 2 !== 0}">
                                                            <div class="row margin-0 box-element bg-light">
                                                                <div class="col-12 box-element-info">
                                                                    <h3 class="font-md">
                                                                        <a href="#" @click.prevent="openModal('hotel-room', {hotelRoom: room})" class="link-style font-weight-bold">{{ room.category }} - {{ room.name}}</a>
                                                                    </h3>
                                                                    <p class="margin-top-40 text-justify">{{ room.description || "" | stripHtml | truncate(200) }}</p>
                                                                </div>
                                                                <div class="col-12 align-self-end box-element-link text-center">
                                                                    <a href="#" @click.prevent="openModal('hotel-room', {hotelRoom: room, showGetQuote: !!currentUser})" class="link-style font-weight-bold font-md">View room</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section-->

                                <!-- Map Section -->
                                <section class="section-style px-xs-0">
                                    <div class="container padding-0">
                                        <h2 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Map</h2>
                                        <div class="overflow-hidden box-shadow rounded">
                                            <google-map :zoom="7" :position="mapPosition" :markers="markers" @marker-clicked="markerClicked"></google-map>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ShareComponentButton from "./../components/misc/ShareComponentButton"
    import SectionBannerBig from "./../components/sections/BannerBig"
    import SectionInteractiveMap from "./../components/sections/InteractiveMap"
    import SpecialOfferModal from "./../components/modals/SpecialOfferModal"
    import HotelRoomModal from "./../components/modals/HotelRoomModal"
    import CarouselModal from "./../components/modals/CarouselModal"
    import AddToMyBucketButton from "../components/misc/AddToMyBucketButton"
    import GoogleMap from "../components/maps/GMap"
    import { vueSlideoutPanelService } from 'vue2-slideout-panel';
    import HotelSidebar from "../components/sidebars/HotelSidebar"
    import utils from "./../services/utils"
    import HotelReviewModal from "../components/modals/HotelReviewModal";
    import {HttpResponseStatus} from "../services/Http/HttpResponse";
    import {filter, find, sampleSize} from "lodash";
    import TestimonialsContainer from "../components/data-containers/TestimoniesContainer";
    import StarRating from 'vue-star-rating';
    import Page404 from "./404"

    export default {
        components: { Page404, AddToMyBucketButton, GoogleMap, SectionBannerBig, ShareComponentButton, SectionInteractiveMap, TestimonialsContainer, StarRating },

        created() {
            this.initialize();
        },

        computed: {
            approvedReviews() {
                return sampleSize(filter(this.reviews, {status: 'Approved'}), 3);
            },
            currentUser() {
                return this.$store.getters["Auth/user"];

            },
            amenities() {
                let response = [];

                if(this.hotel && this.hotel.virtuoso_amenities)
                    response = this.hotel.virtuoso_amenities.split(/\r?\n/).slice(0, 3);

                return response;
            },
            bannerInfo() {
                const response = {
                    title: "",
                    subtitle: "",
                    pictureUrl: "",
                };

                if(this.hotel)
                {
                    response.title = this.hotel.name;
                    response.subtitle = this.hotel.destination.name;
                    response.pictureUrl = this.hotel.main_header_picture_path;
                }

                return response;
            },
            component() {
                return {
                    component_type: "Hotel",
                    component_id: this.hotel ? this.hotel.id : null
                }
            },
            destination() {
                if(!this.hotel) return null;

                return this.$store.getters["Data/getDestinationById"](this.hotel.destination_id);
            },
            galleryPictures() {
                if(!this.hotel) return [];

                return _.filter(this.hotel.hotelPictures, {type: "Gallery"});
            },
            hotelDescription() {
                if(!this.hotel) return "";

                return utils.nl2br(this.hotel.description);
            },
            markers() {
                const vm = this;
                if(!vm.destination) return [];

                return _.reduce(vm.destination.hotels, (carry, hotel) => {
                    if(_.isNumber(hotel.geo_latitude) && _.isNumber(hotel.geo_longitude))
                    {
                        carry.push({
                            id: hotel.id,
                            hotel: hotel,
                            position: {lat: hotel.geo_latitude, lng: hotel.geo_longitude},
                            clickable: !vm.hotel || vm.hotel.id !== hotel.id,
                            icon: vm.hotel && vm.hotel.id === hotel.id ? vm.$globalVariables.get("settings.googleMaps.selectedMarker") : ""
                        })
                    }

                    return carry;
                }, []);
            },
            mapPosition() {
                let position = this.$globalVariables.get("settings.googleMaps.defaultMapPosition");

                let latitude = {min: null, max: null};
                let longitude = {min: null, max: null};

                // Collect the min and max for latitude and longitude
                if(this.destination && this.destination.hotels.length > 0)
                {
                    _.forEach(this.destination.hotels, (hotel) => {
                        if(_.isNumber(hotel.geo_latitude) && _.isNumber(hotel.geo_longitude))
                        {
                            if(latitude.min === null || latitude.min > hotel.geo_latitude)
                                latitude.min = hotel.geo_latitude;

                            if(latitude.max === null || latitude.max < hotel.geo_latitude)
                                latitude.max = hotel.geo_latitude;

                            if(longitude.min === null || longitude.min > hotel.geo_longitude)
                                longitude.min = hotel.geo_longitude;

                            if(longitude.max === null || longitude.max < hotel.geo_longitude)
                                longitude.max = hotel.geo_longitude;
                        }
                    });
                }

                // If min and max were collected successfully, then find the middle point
                if(_.isNumber(latitude.min) && _.isNumber(latitude.max) && _.isNumber(longitude.min) && _.isNumber(longitude.min))
                    position = { lat: ((latitude.max - latitude.min) / 2) + latitude.min, lng: ((longitude.max - longitude.min) / 2) + longitude.min }
                else if(this.hotel && _.isNumber(this.hotel.geo_latitude) && _.isNumber(this.hotel.geo_longitude))
                    position = {lat: this.hotel.geo_latitude, lng: this.hotel.geo_longitude};

                return position;
            },
            picturesForCarousel() {
                if(!this.hotel) return [];

                return _.map(this.galleryPictures, (picture) => {
                    return `<img src="${picture.path}" class="width-100" alt="${this.hotel.name}" />`;
                })
            },
            showReviews() {
                return !!!this.$globalVariables.get('settings.whiteLabel.is_white_label') || this.approvedReviews.length
            }
        },

        data: function () {
            return {
                hotel: null,
                reviews: [],
                loading: false,
                hotelFound: true,
                isLoadingReviews: false,
                readModeDescriptionExpanded: false,
                companyInfo: this.$store.getters["Company/companyInfo"]
            };
        },

        methods: {
            async fetchHotel() {
                this.loading = true;

                await this.$store.dispatch("Data/initializeData");
                this.hotel = await this.$store.getters["Data/getHotelBySlug"](this.$route.params.slug);
                this.hotelFound = !!this.hotel;

                this.loading = false;
            },

            async initialize() {
                await this.fetchHotel();

                await this.fetchReviews();
            },

            async fetchReviews() {
                if (!!!this.hotel || !!!this.hotel.id)
                    return;

                this.isLoadingReviews = true;

                const response = await this.$httpClient.request('GET', `/api/hotel-reviews/load-for-view/${this.hotel.id}`);

                this.isLoadingReviews = false;

                if (response.status === HttpResponseStatus.OK)
                    this.reviews = response.data;
            },

            markerClicked($event, googleMapVueComponent, googleMapMarkerVueComponent, customMarkerInfo) {
                vueSlideoutPanelService.show({
                    width: 550,
                    component: HotelSidebar,
                    cssClass: "slideout-bg-gray",
                    props: {
                        slug: customMarkerInfo.hotel.slug,
                        showGetQuote: !!this.currentUser,
                        showShareButton: !!this.currentUser
                    }
                });
            },

            openModal(modal, props = {}, events = {}) {
                let modalComponent = null;
                let options = {};

                switch (modal)
                {
                    case "special-offer":
                        modalComponent = SpecialOfferModal;
                        options.width = "700px";
                        break;
                    case "hotel-room":
                        modalComponent = HotelRoomModal;
                        options.width = "700px";
                        break;
                    case "carousel":
                        modalComponent = CarouselModal;
                        options.width = "70%";
                        break;
                    case "add-review":
                        modalComponent = HotelReviewModal;
                        options.width = "700px";
                        break;
                }

                this.$modal.show(modalComponent, _.assign({}, props, {showGetQuote: !!this.currentUser}), _.assign({
                    draggable: false,
                    height: 'auto',
                    scrollable: true,
                }, options), events);
            },

            onClosedReviewModal(e) {
                this.fetchReviews();
            }
        },

        watch: {
            '$route.params.slug': function () {
                this.initialize();
            }
        },
    }

</script>
