<template>
    <div> 
        <div class="position-relative">
            <h3 class="bd-title section-title default-letter-spacing text-uppercase font-weight-bold font-md text-center">Quote your trip</h3>
            <loader v-if="loading" class="position-absolute" style="top: -38px; right: 80px"></loader>
        </div>

        <div v-if="!currentComponent" class="row justify-content-end">
            <div class="col-auto">
                <a @click.prevent="loadCurrentComponent(null)" href="#" class="link-style">Add a hotel or destination <i class="material-icons">add</i></a>
            </div>
        </div>

        <div v-if="currentComponent" class="quote-form-cont rounded">
            <div class="box-element-info">
                <form @submit.prevent="saveComponent" class="quote-form">
                    <div class="row">
                        <div class="col-12">
                            <form-errors :errors="currentComponent.errors"></form-errors>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group form-group-multi-2">
                            <label class="font-sm">Hotel or destination</label>
                            <i class="material-icons">search</i>
                            <multiselect 
                                    ref="componentSelect"
                                    v-model="currentComponent.componentData"
                                    @select="mapCurrentComponentInfo"
                                    :options="componentsList"
                                    :multiple="false"
                                    group-values="values"
                                    group-label="group"
                                    :group-select="false"
                                    placeholder=""
                                    label="name">
                                <span slot="noResult">Oops! No components found...</span>
                            </multiselect>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 form-group">
                            <label class="font-sm quote-label-dates">Check in - Check out</label>
                            <i class="material-icons">date_range</i>
                            <div class="form-group">
                                <v-date-picker
                                        mode='range'
                                        v-model='currentComponent.dateRange'
                                        show-caps
                                        is-expanded
                                        :min-date='new Date((new Date()).setDate( (new Date()).getDate() + 1))'
                                        :formats='datePickerFormats'
                                        :pane-width='290'
                                        :input-props='{ class: "form-control input-style", readonly: true }'
                                        :theme-styles='themeStyles'>
                                </v-date-picker>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto form-inc-dec">
                            <label class="font-sm">Adults (Over 12)</label>
                            <a @click.prevent="currentComponent.adults > 1 && (currentComponent.adults -= 1)" href="#"><i class="material-icons align-middle">remove_circle_outline</i></a>
                            <input v-model="currentComponent.adults" name="adults" type="text" class="rounded" aria-describedby="Number of adults">
                            <a href="#" @click.prevent="currentComponent.adults += 1"><i class="material-icons align-middle">add_circle_outline</i></a>
                        </div>        
                        <div class="col-auto form-inc-dec">
                            <label class="font-sm">Children (0 - 11)</label>
                            <a @click.prevent="currentComponent.children > 0 && (currentComponent.children -= 1)" href="#"><i class="material-icons align-middle">remove_circle_outline</i></a>
                            <input v-model="currentComponent.children" name="children" type="text" class="rounded" aria-describedby="Number of children">
                            <a href="#" @click.prevent="currentComponent.children += 1"><i class="material-icons align-middle">add_circle_outline</i></a>
                        </div>
                    </div>

                    <div class="row margin-top-40 margin-bottom-20">
                        <div class="col-6">
                            <button type="submit" class="btn btn-primary btn-lg text-uppercase w-100">{{ currentComponent.uuid ? "Edit" : "Add" }}</button>
                        </div>
                        <div class="col-6">
                            <button @click.prevent="resetCurrentComponent" type="button" class="btn btn-danger btn-lg text-uppercase w-100">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
            
        </div>

        <div class="table-responsive-md margin-top-40 overflow-hidden">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Item</th>
                        <th scope="col" class="text-right">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(quote, index) in components" :key="quote.uuid">
                        <td>{{ index + 1 }}</td>
                        <td valign="center">
                            <span v-if="quote.componentData">{{ quote.componentData.name }}</span>
                            <span v-if="!quote.componentData && quote.component">{{ quote.component.type }}: {{ quote.component.id }}</span>
                            <span v-if="!quote.componentData && !quote.component" class="text-danger">Error</span>
                            <div class="row margin-top-5 margin-bottom-5">
                                <div class="col-auto padding-right-0">
                                    <span>
                                        <i style="font-size: 20px; vertical-align: sub; color: #969696" class="material-icons">nights_stay</i>
                                        <span>{{ quote.dateRange.end.getTime() - quote.dateRange.start.getTime() | duration("asDays") }}</span>
                                    </span>
                                </div>
                                <div class="col-auto padding-right-0">
                                    <span>
                                        <i style="font-size: 20px; vertical-align: sub; color: #969696" class="material-icons">people</i>
                                        <span>{{ quote.adults }}</span>
                                    </span>
                                </div>
                                <div class="col-auto padding-right-0" v-if="quote.children > 0">
                                    <span>
                                        <i style="font-size: 20px; vertical-align: sub; color: #969696" class="material-icons">child_friendly</i>
                                        <span>{{ quote.children }}</span>
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td class="text-right" style="min-width: 85px">
                            <a @click.prevent="loadCurrentComponent(index)" href="#" class="margin-right-10 d-inline-block"><i class="material-icons">edit</i></a>
                            <a @click.prevent="deleteComponent(quote.uuid)" href="#" class="d-inline-block"><i class="material-icons">delete</i></a>
                        </td>
                    </tr>
                    <tr v-if="components.length">
                        <td colspan="3" style="padding:0 !important">
                            <form v-if="$globalVariables.get('settings.whiteLabel.is_white_label')" @submit.prevent="">
                                <div class="row">
                                    <div class="col-12 col-md-6 form-group">
                                        <label class="font-sm">Name</label>
                                        <i class="material-icons">person_outline</i>
                                        <input v-model="quote.client_name" name="quote-name" type="text" class="form-control input-style" aria-describedby="Name" />
                                    </div>
                                    <div class="col-12 col-md-6 form-group">
                                        <label class="font-sm">Email</label>
                                        <i class="material-icons">mail_outline</i>
                                        <input v-model="quote.client_email" name="quote-email" type="text" class="form-control input-style" aria-describedby="Email" />
                                    </div>
                                </div>
                            </form>
                             <div class="row margin-top-40 margin-bottom-20">
                                <div class="col-12">
                                    <button :disabled="loading" @click.prevent="quoteCartCheckout" type="button" class="btn btn-action btn-lg text-uppercase w-100">
                                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Get a quote
                                    </button>
                                </div>
                             </div>
                        </td>
                    </tr>
                    <tr v-if="components.length === 0">
                        <td colspan="4" class="padding-0">
                            <div class="alert alert-warning box-shadow margin-top-40 box-round" role="alert">
                                <div class="row">
                                    <div class="col-2">
                                        <i class="material-icons">shopping_basket</i>
                                    </div>
                                    <div class="col-10">
                                        <h4 class="font-md"><strong>Your Shopping Basket is empty?!</strong></h4>
                                        <p class="margin-bottom-0">No worries, we've got your back!</p>
                                    </div>
                                </div>
                                <hr style="margin: 1.6rem 0">
                                <ol>
                                    <li>Start by clicking the link above <strong>"Add a hotel or destination +"</strong></li>
                                    <li>Select a hotel or destination</li>
                                    <li>Input the dates and guests, and click <strong>"Add"</strong></li>
                                </ol>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex"
    import Loader from "./Loader"
    import FormErrors from "./FormErrors"
    import init from "./../../store/helpers/initializationValues"
    import Multiselect from 'vue-multiselect'
    import ThankYouModal from "../modals/ThankYouModal";
    import multiAnalytics from "../../../shared-js/multi-analytics";

    export default {
        components: { FormErrors, Loader, Multiselect },

        created() {
            this.initialize();
        },

        mounted() {
            if(!this.componentId || !this.componentType)
                return;

            const componentData = this.$store.getters["Data/get"+this.componentType+"ById"](this.componentId);
            this.loadCurrentComponent(null);
            this.currentComponent.componentData = componentData;
            this.mapCurrentComponentInfo(componentData);
        },

        props: {
            componentId: {
                default: null,
                type: Number|String
            },
            componentType: {
                default: "",
                type: String
            },
        },

        computed: {
            ...mapGetters({
                loading: "QuoteCart/loading",
                components: "QuoteCart/data"
            }),
            currentUser() {
                return !_.get(this.globalSettings, 'whiteLabel.is_white_label')
                    ? this.$store.getters["Auth/user"]
                    : this.$store.getters["Users/getByToken"](this.$route.params.token);
            },
            componentsList() {
                if(this.$store.getters["Data/initialized"] !== init.INITIALIZED)
                    return [];

                return [
                    {
                        group: "Destinations",
                        values: this.$store.getters["Data/destinations"]
                    },
                    {
                        group: "Hotels",
                        values: this.$store.getters["Data/hotels"]
                    },
                ];
            },
            globalSettings() {
                return this.$globalVariables.get('settings');
            },
        },

        data() {
            const hSpacing = '20px';
            return {
                currentComponent: null,
                datePickerFormats: {
                    title: 'MMMM YYYY',
                    weekdays: 'W',
                    navMonths: 'MMM',
                },
                themeStyles: {
                    wrapper: {
                        background: '#fff',
                        color: '#fafafa',
                        border: '0',
                        borderRadius: '5px',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 6px 20px 0 rgba(0, 0, 0, 0.13)'
                    },
                    header: {
                        padding: `20px ${hSpacing}`,
                        fontWeight: '900',
                        fontSize: '1rem',
                    },
                    weekdays: {
                        color: '#868686', // New color
                        fontWeight: '600', // And bolder font weight
                        padding: `20px ${hSpacing} 5px ${hSpacing}`,
                    },
                    weeks: {
                        padding: `0 ${hSpacing} ${hSpacing} ${hSpacing}`,
                    },
                    dayContent: {
                        fontSize: '0.9rem'
                    }
                },
                quote: {
                    client_name: "",
                    client_email: "",
                }
            }
        },

        methods: {
            initialize() {
                this.$store.dispatch("Data/initializeData");
                this.$store.dispatch("QuoteCart/initializeData");
            },

            ...mapActions({
                deleteComponent: "QuoteCart/deleteComponent",
            }),

            loadCurrentComponent(index) {
                let value = null;

                if(index !== null)
                    value = this.$store.getters["QuoteCart/getByIndex"](index);
                else
                {
                    value = this.$store.getters["QuoteCart/defaultComponentStructure"];
                    value.position = this.components.length;
                }

                value.errors = [];

                this.currentComponent = _.cloneDeep(value);
            },

            mapCurrentComponentInfo(componentData) {
                this.currentComponent.component = {
                    id: componentData.id,
                    type: _.has(componentData, "hotels") ? "Destination" : "Hotel",
                };
            },

            async quoteCartCheckout() {
                let data = {
                    token: this.$route.params.token,
                    client_name: this.quote.client_name,
                    client_email: this.quote.client_email,
                };

                const callback = () => {
                    this.$emit('quoteSent');

                    this.$modal.show(ThankYouModal, {
                        displayName: _.get(this.globalSettings, 'whiteLabel.is_white_label')
                            ? data.client_name
                            : this.currentUser.full_name
                    }, {
                        draggable: false,
                        height: 'auto',
                        width: "700px",
                        scrollable: true
                    });
                    if(!_.get(this.globalSettings, 'whiteLabel.is_white_label'))
                        multiAnalytics.lead();
                };

                if(await this.$store.dispatch("QuoteCart/checkout", {data, callback}))
                {
                    this.quote.client_name = "";
                    this.quote.client_email = "";
                }
            },

            resetCurrentComponent() {
                this.currentComponent = null;
            },

            saveComponent() {
                this.validateComponent();

                if(this.currentComponent.errors.length)
                    return;

                const action = this.currentComponent.uuid ? "editComponent" : "addComponent";

                if(this.$store.dispatch("QuoteCart/"+action, _.cloneDeep(this.currentComponent)))
                    this.resetCurrentComponent();
            },

            validateComponent() {
                this.currentComponent.errors = [];

                if(!this.currentComponent.component || !this.currentComponent.component.id || !this.currentComponent.component.type)
                    this.currentComponent.errors.push("A hotel or destination is required");

                if(!this.currentComponent.dateRange || !this.currentComponent.dateRange.start || !this.currentComponent.dateRange.end)
                    this.currentComponent.errors.push("A valid checkin and checkout are required");

                if(this.currentComponent.adults < 1)
                    this.currentComponent.errors.push("The number of guests is incorrect");
            }
        }
    }
</script>
<style>

</style>